import { render, staticRenderFns } from "./Popup.vue?vue&type=template&id=1ca05336"
import script from "./Popup.vue?vue&type=script&lang=js"
export * from "./Popup.vue?vue&type=script&lang=js"
import style0 from "@/assets/popup.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./Popup.vue?vue&type=style&index=1&id=1ca05336&prod&lang=css"
import style2 from "./Popup.vue?vue&type=style&index=2&id=1ca05336&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports