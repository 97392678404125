<template>
  <div id="Popup-root">
    <transition-group name="popup">
      <div
        v-for="item in Window"
        :key="item.modal"
        class="Popup_Parent"
      >
        <div class="Popup-bg" :class="{disabled: item.disabledBg}" @click="closeWindow(true, true)"></div>
        <div
          v-if="item.modal === 1 || item.modal === 1.1"
          :id="item.ID"
          class="Popup_Inner"
          :class="Modal1.isDisabled ? 'is-disabled' : ''"
          style="max-width: 217rem; max-height: 64em"
        >
          <div
            class="Popup_Inner_main"
            style="display: flex; flex-direction: column"
          >
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div class="Popup_title">
              {{ item.title }}
            </div>
            <div
              class="modalRow"
              style="
                flex-grow: 1;
                overflow: auto;
                padding-right: 1rem;
                margin-right: -1rem;
              "
            >
              <div class="leftSection" style="max-width: 50%">
                <template>
                  <div class="WindowDetails">
                    <div
                      v-if="getUserInstitution()"
                      class="WindowDetails_child"
                    >
                      <img
                        src="@/assets/media/institutions.svg"
                        alt="Institutie"
                      />
                      {{
                        item.origin && item.origin.institution
                          ? item.origin.institution.name
                          : getUserInstitution()
                      }}
                    </div>
                    <div v-if="getUserDepartment()" class="WindowDetails_child">
                      <img
                        src="@/assets/media/departments.svg"
                        alt="Institutie"
                      />
                      {{
                        item.origin && item.origin.department
                          ? item.origin.department.name
                          : getUserDepartment()
                      }}
                    </div>
                    <div class="WindowDetails_child">
                      <i class="fas fa-user-tie"></i>
                      {{ item.origin ? item.origin._name : getUserName() }}
                    </div>
                    <div class="right">
                      <b style="line-height: 0.7">
                        {{ currentDateAndTime }}
                      </b>
                    </div>
                  </div>
                </template>
                <div class="Popup_body">
                  <template>
                    <div
                      v-if="isSuperadmin"
                      class="form-group"
                      :class="{ WRNG: Modal1.invalids.i7 }"
                    >
                      <b>Instituția</b>
                      <v-select
                        v-model="Modal1.Input7"
                        :loading="
                          emptyData.institutions || Data.institutionData.length
                            ? false
                            : true
                        "
                        label="name"
                        :filter="(a, b) => fuseFilter(a, b, 'name')"
                        :options="Data.institutionData.filter((e) => e.name)"
                      />
                    </div>
                    <div
                      v-if="isAdmin"
                      class="form-group"
                      :class="{ WRNG: Modal1.invalids.i8 }"
                    >
                      <b>Structură organizatorică</b>
                      <v-select
                        v-model="Modal1.Input8"
                        :loading="
                          emptyData.departments || Data.departmentData.length
                            ? false
                            : true
                        "
                        label="name"
                        :filter="(a, b) => fuseFilter(a, b, 'name')"
                        :disabled="
                          departmentsFromInstitution[0].length ||
                          departmentsFromInstitution[1]
                            ? false
                            : true
                        "
                        :options="
                          departmentsFromInstitution[0].filter((e) => e.name)
                        "
                      />
                    </div>
                  </template>
                  <div class="Popup_body_cols">
                    <div class="Popup_body_col form-vertical_extend-inputs" style="height: initial !important;">
                      <div
                        class="form-group"
                        :class="{ WRNG: Modal1.invalids.i1 }"
                        style="z-index: 1000;position: relative;"
                      >
                        <b>Nevoie</b>
                        <v-select
                          ref="productsCatalog"
                          :id="`ID${makeid(6)}`"
                          v-model="Modal1.Input1"
                          :loading="
                            item.modal !== 1
                              ? false
                              : emptyData.productData || Data.productData.length
                              ? false
                              : true
                          "
                          label="name"
                          :options="Data.productData.filter((e) => e.name)"
                          :filter="(a, b) => fuseFilter(a, b, 'name')"
                          :disabled="item.modal !== 1"
                          :no-drop="item.modal !== 1"
                          :clearable="item.modal === 1"
                          @close="selectIsEmpty = false"
                          @empty="selectIsEmpty = true"
                          @noEmpty="selectIsEmpty = false"
                        >
                          <template #option="o">
                            <span class="v-select_li_custom">
                              <span>{{ o.name }} <i v-if="o.status == 'in_progres'" style="pointer-events:none;">(în curs de validare)</i></span>
                              <i v-tooltip="{
                                'in_progres': 'În curs de validare',
                                'completed': 'Validat'
                              }[o.status]" :class="{
                                'in_progres': 'fad fa-hourglass',
                                'completed': 'fas fa-clipboard-check'
                              }[o.status]" style="margin-right: 0.75rem;"></i>
                              <i v-tooltip="o.name" class="fas fa-info-circle"></i>
                            </span>
                          </template>
                          <template
                            v-if="PERMISIONS.productsCreate && selectIsEmpty"
                            #list-footer
                          >
                            <li
                              style="
                                text-align: center;
                                margin-top: 1rem;
                                margin-bottom: 0.5rem;
                              "
                            >
                              <div
                                @click="addProduct"
                                class="Button1 Btn_center"
                              >
                                <div class="icon">
                                  <img
                                    style="opacity: 0"
                                    @load="imgLoaded"
                                    src="../assets/media/for_table/circle_ADD_new_etap.svg"
                                    alt=""
                                  />
                                </div>
                                <span class="Btn_content Btn_center">
                                  Adaugă
                                </span>
                              </div>
                            </li>
                          </template>
                        </v-select>
                      </div>


                      <div class="form-flex form-adapt-10">
                        <div class="form-group" :class="{ WRNG: Modal1.invalids.i5 }">
                          <b>Cantitate</b>
                          <inp-number
                            v-model="Modal1.Input5"
                            min="1"
                            :isFloat="false"
                          /></div>
                        </div>
                      <div class="form-flex form-adapt-10">
           
                        <div
                          class="form-group"
                          :class="{ WRNG: Modal1.invalids.cotaTva }"
                        >
                          <b>Cotă TVA %</b>
                          <inp-number
                            v-model="Modal1.cotaTva"
                            min="1"
                            max="100"
                            :floatLength="2"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="Popup_body_col form-vertical_extend-inputs" style="height: initial !important;">
                      <div class="form-flex form-adapt-1">
                        <div
                          class="form-group"
                          :class="{ WRNG: Modal1.invalids.i1 }"
                        >
                          <b>Tip u. m.</b>
                          <v-select
                            :disabled="true"
                            :no-drop="true"
                            :select-on-tab="false"
                            :clearable="false"
                            v-model="Modal1.Input1"
                            label="measurementUnit"
                          />
                        </div>
                        <div
                          class="form-group"
                          :class="{ WRNG: Modal1.invalids.i1 }"
                        >
                          <b>Cod CPV</b>
                          <div
                            v-tooltip="
                              Modal1.Input1
                                ? `${Modal1.Input1.productCpvCode} - ${Modal1.Input1.cpvName}`
                                : ''
                            "
                          >
                            <input
                              type="text"
                              :value="
                                Modal1.Input1
                                  ? `${Modal1.Input1.productCpvCode} - ${Modal1.Input1.cpvName}`
                                  : ''
                              "
                              :disabled="true"
                              class="vInput form-disabled"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-flex form-adapt-10">
                        <div
                          class="form-group"
                          :class="{ WRNG: Modal1.invalids.i3 }"
                        >
                          <b>V.U.E fără TVA <i class="fas fa-info-circle" v-tooltip="'Valoare estimată/u.m. fără TVA'"></i></b>
                          <number-input
                            v-model="Modal1.Input3"
                            :floatLength="2"
                          />
                        </div>
                        <div
                          class="form-group"
                          :class="{ WRNG: Modal1.invalids.i3 }"
                        >
                          <b>V.T.E fără TVA <i class="fas fa-info-circle" v-tooltip="'Valoare totală fără TVA'"></i></b>
                          <input
                            type="text"
                            :value="prepareLocaleNumber(toFixedIfNecessary(_Multiply(prepareTva(Modal1.Input3, 0), Modal1.Input5), 2))"
                            class="vInput form-disabled"
                            :disabled="true"
                          />
                        </div>
                      </div>
                      <div class="form-flex form-adapt-10">
                        <div
                          class="form-group"
                        >
                          <b>V.U.E cu TVA <i class="fas fa-info-circle" v-tooltip="'Valoare unitară estimată cu TVA'"></i></b>
                          <input
                            type="text"
                            :value="prepareLocaleNumber(toFixedIfNecessary(prepareTva(Modal1.Input3, Modal1.cotaTva), 2))"
                            class="vInput form-disabled"
                            :disabled="true"
                          />
                        </div>
                        <div
                          class="form-group"
                        >
                          <b>V.T.E cu TVA <i class="fas fa-info-circle" v-tooltip="'Valoare totală estimată cu TVA'"></i></b>
                          <input
                            type="text"
                            :value="prepareLocaleNumber(toFixedIfNecessary(_Multiply(prepareTva(Modal1.Input3, Modal1.cotaTva), Modal1.Input5), 2))"
                            class="vInput form-disabled"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <files-manager
                    v-model="Modal1.files"
                    :signDeleted="true"
                    :classes="Modal1.invalids.files ? 'WRNG' : ''"
                    :style="Modal1.canAvizo ? '' : 'margin-bottom: 0.5rem;'"
                    :allowExtensions="['png','jpg','jpeg','doc','docx','pdf']"
                  >
                    <template v-if="item.modal === 1.1" v-slot:label-right>
                      <span style="cursor: pointer;" @click="viewEditingNeedFiles()">
                        <i class="fas fa-eye"></i>
                      </span>
                    </template>
                  </files-manager>
                </div>
                <template v-if="Modal1.approveMode">           
                    <div class="hr1"></div>
                    <div align="right">
                    <label
                      style="
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        line-height: 1;
                        cursor: pointer;
                        margin-top: 0.5rem;
                        color: #333;
                      "
                      :for="`Checkbox_${item.ID}`"
                    >
                      <span style="margin-right: 0.5rem"
                        >Trimite spre aprobare</span
                      >
                      <input
                        v-model="Modal1.approveState"
                        :id="`Checkbox_${item.ID}`"
                        style="margin-top: 0"
                        type="checkbox"
                      />
                    </label>
                    </div>
                      <div
                        class="form-group"
                        :class="{ WRNG: Modal1.invalids.i12 }"
                        style="margin: 0.5rem 0px 1rem"
                        v-if="Modal1.approveState"
                      >
                        <b>Aprobator</b>
                        <v-select
                        
                          :options="filteredNeedApprover"
                          v-model="Modal1.Input12"
                          :getOptionKey="(op) => op.label"
                        >
                          <template #option="{ label }">
                            <span class="v-select_li_custom">
                              <span>{{ label }}</span>
                              <i v-tooltip="label" class="fas fa-info-circle"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                  </template>
                <template
                  v-if="Modal1.canAvizo && (item.modal === 1 || item.showAvizo || item.needApprover) && Modal1.approveState"
                >
                  <div class="hr1" style="margin-bottom: 0.5rem"></div>
                  <div align="right">
                    <label
                      style="
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        line-height: 1;
                        cursor: pointer;
                        margin-top: 0.5rem;
                        color: #333;
                      "
                      :for="`Checkbox_${item.ID}`"
                    >
                      <span style="margin-right: 0.5rem"
                        >Trimite spre avizare</span
                      >
                      <input
                        v-model="Modal1.revisionState"
                        :id="`Checkbox_${item.ID}`"
                        style="margin-top: 0"
                        type="checkbox"
                      />
                    </label>
                  </div>
                  <div
                    v-if="Modal1.revisionState"
                    class="form-group"
                    :class="{ WRNG: Modal1.invalids.i11 }"
                  >
                    <b>Selectează tipul de flux</b>
                    <v-select
                      :options="Modal1.revisionStates"
                      :overflowPrevent="false"
                      label="title"
                      :searchable="false"
                      v-model="Modal1.Input11"
                    >
                      <template #option="item">
                        <span class="v-select_li_custom">
                          <span>{{ item.title }}</span>
                          <i
                            v-tooltip="{
                              content: item.desc,
                              classes: ['Tooltip-hide_arrow', 'Tooltip-large'],
                            }"
                            class="fas fa-info-circle"
                          ></i>
                        </span>
                      </template>
                    </v-select>
                  </div>
                  <div
                    v-if="Modal1.revisionState"
                    class="reducePaddingMobile1"
                    style="padding-bottom: 20rem"
                    :style="Modal1.revisionState ? 'min-height: 10rem;' : ''"
                  >
                    <div
                      class="form-group"
                      :class="{ WRNG: Modal1.invalids.i9 }"
                      style="margin-bottom: 0.5rem !important"
                    >
                      <b>Selectează avizatorii din cadrul instituției</b>
                      <v-select
                        :overflowPrevent="false"
                        @option:selecting="selectedUserNeed"
                        :options="filteredNeedAvizo"
                        :value="null"
                        :loading="!Modal1.Input9.length && !emptyData.needUsers"
                        :disabled="!Modal1.Input12"
                        v-tooltip="
                          Modal1.Input12 ? '' : 'Mai întâi alegeți aprobatorul'
                        "
                      >
                        <template #option="{ label }">
                          <span class="v-select_li_custom">
                            <span>{{ label }}</span>
                            <i v-tooltip="label" class="fas fa-info-circle"></i>
                          </span>
                        </template>
                      </v-select>
                    </div>
                    <div
                      class="form-group"
                      :class="{ WRNG: 0 }"
                      style="margin-bottom: 0.5rem !important"
                    >
                      <b>Selectează avizatorii din instituții externe</b>
                      <v-select
                        :overflowPrevent="false"
                        @option:selecting="selectedUserNeed"
                        :options="filteredNeedExternalAvizo"
                        :value="null"
                        :loading="
                          !Modal1.Input9.length && !emptyData.externalAvizo
                        "
                        :disabled="!Modal1.Input12"
                        v-tooltip="
                          Modal1.Input12 ? '' : 'Mai întâi alegeți aprobatorul'
                        "
                      >
                        <template #option="{ label }">
                          <span class="v-select_li_custom">
                            <span>{{ label }}</span>
                            <i v-tooltip="label" class="fas fa-info-circle"></i>
                          </span>
                        </template>
                      </v-select>
                    </div>
                    <div
                      v-if="Modal1.revisionState && Modal1.selectedAvizo.length"
                      class="sic_list"
                      style="margin-top: 1rem"
                    >
                      <ol>
                        <draggable v-model="Modal1.selectedAvizo" group="avizo">
                          <li
                            v-for="item in Modal1.selectedAvizo"
                            :key="item.id"
                          >
                            <div>
                              <span style="text-align: left">{{
                                item.label
                              }}</span>
                              <div class="sic_list_actions">
                                <div
                                  @click="selectedUserNeed(item, true)"
                                  class="sic_list_remove"
                                >
                                  <i class="fas fa-times"></i>
                                </div>
                              </div>
                            </div>
                          </li>
                        </draggable>
                      </ol>
                    </div>
                  </div>
                </template>
              </div>
              <div class="rightSection">
                <div
                  class="form-group fullHeightWysiwyg"
                  style="margin-bottom: 0 !important"
                >
                  <div
                    class="form-group"
                    :class="{ WRNG: Modal1.invalids.isSocial }"
                  >
                    <b>Caracter nevoie</b>
                    <input
                      type="text"
                      :value="Modal1.Input1 && Modal1.Input1.cpvCategory.isSocial ? Modal1.Input1.cpvCategory.description : Modal1.Input1 ? cpvType[Modal1.Input1.cpvCategory.cpvType] : ''"
                      :disabled="true"
                      class="vInput form-disabled"
                    />
                 <!--    <v-select
                      :options="Modal1.socialTypes"
                      v-model="Modal1.isSocial"
                      :searchable="false"
                      :filterable="false"
                    >
                      <template #option="{ label }">
                        <span class="v-select_li_custom">
                          <span>{{ label }}</span>
                          <i v-tooltip="label" class="fas fa-info-circle"></i>
                        </span>
                      </template>
                    </v-select> -->
                  </div>
                  <div
                    class="form-group"
                    :class="{ WRNG: Modal1.invalids.i13 }"
                  >
                    <b
                      >Modalitatea utilizată pentru descrierea
                      caracteristicilor</b
                    >

                    <v-select
                      :options="Modal1.describeTheCharacteristics"
                      v-model="Modal1.theCharacteristics"
                      label="name"
                      :searchable="false"
                      :filterable="false"
                    >
                      <template #option="{ name }">
                        <span class="v-select_li_custom">
                          <span>{{ name }}</span>
                          <i v-tooltip="{content: name, boundariesElement: 'window'}" class="fas fa-info-circle"></i>
                        </span>
                      </template>
                    </v-select>
                  </div>
                  <div
                    v-if="Modal1.showPapp"
                    class="form-group"
                    :class="{ WRNG: Modal1.invalids.i14 }"
                  >
                    <b
                      >Caietul de sarcini va include trimiteri la următoarele
                      categorii de standarde (ordinea de prioritate a
                      standardelor astfel cum este stabilită la art. 156
                      alin.(1), lit b) din Legea 98/2016):</b
                    >
                    <div class="solidCheckboxes" style="color: #555">
                      <div>
                        <label for="PaapBox_1"
                          ><input
                            type="checkbox"
                            id="PaapBox_1"
                            v-model="Modal1.paap[0]"
                          />
                          SR EN ISO</label
                        >
                      </div>
                      <div>
                        <label for="PaapBox_2"
                          ><input
                            type="checkbox"
                            id="PaapBox_2"
                            v-model="Modal1.paap[1]"
                          />
                          Evaluări tehnice europene [acolo unde este
                          aplicabil]</label
                        >
                      </div>
                      <div>
                        <label for="PaapBox_3"
                          ><input
                            type="checkbox"
                            id="PaapBox_3"
                            v-model="Modal1.paap[2]"
                          />
                          Specificaţii tehnice comune [acolo unde este
                          aplicabil]</label
                        >
                      </div>
                      <div>
                        <label for="PaapBox_4"
                          ><input
                            type="checkbox"
                            id="PaapBox_4"
                            v-model="Modal1.paap[3]"
                          />
                          Standarde internaţionale</label
                        >
                      </div>
                      <div>
                        <label for="PaapBox_5"
                          ><input
                            type="checkbox"
                            id="PaapBox_5"
                            v-model="Modal1.paap[4]"
                          />
                          Sisteme de referinţă tehnice instituite de organisme
                          de standardizare europene [acolo unde este
                          aplicabil]</label
                        >
                      </div>
                      <div>
                        <label for="PaapBox_6"
                          ><input
                            type="checkbox"
                            id="PaapBox_6"
                            v-model="Modal1.paap[5]"
                          />
                          Alte norme la nivel naţional care reglementează
                          aspecte tehnice/specificaţii tehnice naţionale</label
                        >
                      </div>
                      <div>
                        <label for="PaapBox_7"
                          ><input
                            type="checkbox"
                            id="PaapBox_7"
                            v-model="Modal1.paap[6]"
                          />
                          Conform descrierii tehnice menționate</label
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    :class="{ WRNG: Modal1.invalids.i10 }"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin: 0;
                    "
                  >
                    <b
                      >Descriere tehnică
                      <i
                        @click="Modal1.temporar = !Modal1.temporar"
                        class="clickableI"
                        :class="`fas fa-${
                          Modal1.temporar ? 'minus' : 'plus'
                        }-circle`"
                        v-tooltip="
                          `${
                            Modal1.temporar ? 'Ascunde' : 'Vezi'
                          } tot funcționalul editorului (temporar)`
                        "
                      ></i
                    ></b>
                    <transition name="popup">
                      <span
                        v-show="Modal1.Input1"
                        @click="openNeedDesc"
                        class="blueHover"
                        style="cursor: pointer; margin-top: -5px"
                      >
                        <i
                          :class="`fas fa-search-plus`"
                        ></i>
                      </span>
                    </transition>
                  </div>
                  <editor
                    :key="`${Modal1.temporar}${Modal1.editorKey}`"
                    v-model="Modal1.Input10"
                    :fullMode="Modal1.temporar"
                    minHeight="180"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button @click="Modal1Save" class="Popup_footer_btn Footer_btn2">
                {{ Modal1.approveState ? 'Salvează și Trimite' : 'Salvează' }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 1.2"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div class="Propuneri">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div
                v-if="item.data && item.data.product && item.data.product.name"
                class="Popup_subTitle"
              >
                {{ item.data.product.name }}
              </div>
              <ul class="DeparmentDeleting_Parent" style="text-align: left">
                <template v-if="item.data">
                  <li>
                    Nevoia:
                    <ul v-if="item.data.product">
                      <li>
                        Denumire: <b v-html="item.data.product.name || '-'"></b>
                      </li>
                      <li>
                        Descriere:
                        <b v-html="item.data.product.description || '-'"></b>
                      </li>
                    </ul>
                    <b v-else>-</b>
                  </li>
                  <li>
                    Preț estimat/unitate de măsură:
                    <b>{{
                      `${item.data.estimatedPrice}/${item.data.muName}`
                    }}</b>
                  </li>
                  <li>
                    Cantitate: <b>{{ item.data.qty }}</b>
                  </li>
                  <li>
                    Valoarea estimată totală: <b>{{ item.data.totalPrice }}</b>
                  </li>
                  <li>
                    Status: <b>{{ statusNormalize(item.data.status) }}</b>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button @click="deleteNeed" class="Popup_footer_btn Footer_btn3">
                Sterge
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 2"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-width: 100rem; max-height: 45em;"
        >
          <div
            class="Popup_Inner_main"
            style="padding-left: 0 !important; padding-right: 0 !important"
          >
            <div class="Popup_title">Copiază descriere tehnică</div>
            <div class="Popup_subTitle">
              {{ Modal1.Input1 ? `Nevoie: ${Modal1.Input1.name}` : "" }}
            </div>
            <div
              v-if="Data.needDesc.length"
              class="Popup_Files"
              style="
                height: auto;
                border-top: 1px solid #c3c3c3;
                padding-top: 1rem;
                margin-top: 1rem;
              "
            >
              <div
                class="Popup_files_list"
                style="min-width: 20rem; max-width: 20rem"
              >
                <div
                  v-for="(need, i) in Data.needDesc"
                  :key="i"
                  @click="selectDescNeed(i)"
                  class="PULeftBtn"
                  :class="{ PUSelected: i === Modal2.selectedNeed }"
                >
                  Descriere {{ i + 1 }}
                </div>
              </div>
              <div class="Popup_files_preview">
                <h4
                  v-if="
                    Modal2.selectedNeed === -1 ||
                    !Data.needDesc[Modal2.selectedNeed]
                  "
                >
                  Nimic nu a fost selectat
                </h4>
                <div
                  v-else
                  style="
                    margin-right: 1rem;
                    text-align: left;
                    max-height: 47rem;
                  "
                >
                  <div
                    style="white-space: pre-wrap"
                    v-html="
                      Data.needDesc[Modal2.selectedNeed].technicalDescription
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div v-else>
              <h4
                class="prettyH4"
              >
                <i class="fas fa-times"></i>
                Nu sunt descrieri tehnice similare produsului selectat
              </h4>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button
                @click="closeWindowWithStopPrevent"
                class="Popup_footer_btn Footer_btn1"
              >
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <transition name="popup">
                <button
                  v-if="Data.needDesc.length"
                  v-show="Modal2.selectedNeed > -1"
                  @click="saveNeedDesc"
                  class="Popup_footer_btn Footer_btn2"
                >
                  Copiază
                </button>
              </transition>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 3"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div>
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <ul class="DeparmentDeleting_Parent" style="text-align: left">
                <template v-if="item.institutionData">
                  <li>
                    Denumire: <b>{{ item.institutionData.name }}</b>
                  </li>
                  <li>
                    Email: <b>{{ item.institutionData.email }}</b>
                  </li>
                  <li>
                    Adresa: <b>{{ item.institutionData.address }}</b>
                  </li>
                </template>
                <template v-else-if="item.departmentData">
                  <li>
                    Denumire: <b>{{ item.departmentData.name }}</b>
                  </li>
                  <li>
                    Email: <b>{{ item.departmentData.email }}</b>
                  </li>
                  <li>
                    Instituția inovatoară:
                    <b>{{ item.departmentData.institutionName }}</b>
                  </li>
                  <li>
                    Structura organizatorică părinte:
                    <b>{{ item.departmentData.departmentName }}</b>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button
                v-if="item.institutionData"
                @click="
                  deleteDepartmentOrInstitution(1, item.institutionData.id)
                "
                class="Popup_footer_btn Footer_btn3"
              >
                Sterge
              </button>
              <button
                v-else-if="item.departmentData"
                @click="
                  deleteDepartmentOrInstitution(2, item.departmentData.id)
                "
                class="Popup_footer_btn Footer_btn3"
              >
                Sterge
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 4"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div>
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <div class="InstitutionImgView internalPreloader">
                <img
                  v-if="
                    !String(item.institutionData.picture).trim() ||
                    String(item.institutionData.picture).trim() === '-'
                  "
                  @load="imgLoaded"
                  src="../assets/media/institution_placeholder_default.jpg"
                  alt=""
                />
                <img
                  v-else
                  @load="imgLoaded"
                  :src="item.institutionData.picture"
                  alt=""
                />
              </div>
              <ul class="DeparmentDeleting_Parent" style="text-align: left">
                <li>
                  Denumire: <b>{{ item.institutionData.name }}</b>
                </li>
                <li>
                  Email: <b>{{ item.institutionData.email }}</b>
                </li>
                <li>
                  Adresa: <b>{{ item.institutionData.address }}</b>
                </li>
                <li>
                  Administratori:
                  <b v-if="!item.institutionData.admins.length">-</b>
                  <ul v-else>
                    <li
                      v-for="(admin, i) in item.institutionData.admins"
                      :key="i"
                    >
                      <b>{{ admin.email }}</b>
                      <span>
                        {{ `(${admin.last_name} ${admin.first_name})` }}</span
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col"></div>
          </div>
        </div>
        <div
          v-if="item.modal === 5 || item.modal === 5.1"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-width: 217rem; max-height: 64em"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
              "
            >
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <div
                v-if="isSuperadmin"
                class="form-group"
                style="margin-bottom: 1rem"
                :class="{ WRNG: Modal5.invalids.i5 }"
              >
                <b>Instituția</b>
                <v-select
                  v-model="Modal5.Input5"
                  :loading="
                    emptyData.institutions || Data.institutionData.length
                      ? false
                      : true
                  "
                  label="name"
                  :filter="(a, b) => fuseFilter(a, b, 'name')"
                  :options="Data.institutionData.filter((e) => e.name)"
                  :disabled="item.readonly"
                />
              </div>
              <div
                class="Popup_body_cols"
                style="align-items: stretch; margin-bottom: 10px"
              >
                <div class="Popup_body_col">
                  <div class="form-flex form-adapt-2">
                    <div
                      class="form-group"
                      style="position: relative;"
                      :class="{ WRNG: Modal5.invalids.i1, noMB: !item.readonly }"
                    >
                      <b>Denumire necesitate</b>
                      <input
                        type="text"
                        v-model="Modal5.Input1"
                        class="vInput"
                        :class="{'form-disabled': item.readonly}"
                        :disabled="item.readonly"
                        @input="event => handleChangeModal5Input1(event.target.value)"
                        @focusout="onFocusOutModal5Input1"
                        @keydown.tab="onFocusOutModal5Input1"
                      />
                      <div v-if="products.length > 0" class="modal5-names">
                        <div style="font-size: 11px; font-weight: 700;margin-bottom: 2px; padding: 4px">
                          Nevoi similare:
                        </div>
                        <div class="modal5-names-item" v-for="item in products" @click="onClickProduct(item)">
                          <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-right: 5px;"> {{ item.name }} - {{ item.cpvCategory.cpvCode }}</span>
                          <div style="display: flex;">
                            <i v-tooltip="{
                                'in_progres': 'În curs de validare',
                                'completed': 'Validat'
                              }[item.status]" :class="{
                                'in_progres': 'fad fa-hourglass',
                                'completed': 'fas fa-clipboard-check'
                              }[item.status]" style="margin-right: 0.75rem;"></i>

                              <i v-tooltip="`${item.name} - ${item.cpvCategory.cpvCode}`" class="fas fa-info-circle"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group"
                      :class="{ WRNG: Modal5.invalids.i3, noMB: !item.readonly }"
                    >
                      <b>Unitatea de măsură</b>
                      <v-select
                        v-model="Modal5.Input3"
                        label="name"
                        :loading="
                          emptyData.muData || convertedMuData.length
                            ? false
                            : true
                        "
                        :filter="(a, b) => fuseFilter(a, b, 'name')"
                        :options="convertedMuData"
                        :disabled="item.readonly"
                      />
                    </div>
                  </div>
              <!--     <template v-if="PERMISIONS.confirmProducts && (item.modal === 5 || item.readonly)">
                    <h3 style="margin: 0.8rem 0 0.5rem !important">
                      Categorii de achiziție
                    </h3>
                    <div class="hr1" style="margin: 1rem 0"></div>
                    <div :class="{ WRNG: Modal5.invalids.i7 }" style="margin-top: 0.5rem;">
                      <component 
                        :is="achizitionsSelectModule"
                        :initial="Modal5.achizitionCategories"
                        :readonly="item.readonly"
                        v-model="Modal5.Input7"
                      />
                    </div>
                  </template> -->
                  <template v-if="!item.readonly">
                    <div class="hr1" style="margin: 1rem 0"></div>
                    <h3 style="margin: 0.8rem 0 0.5rem !important">
                      Criterii căutare Cod CPV
                    </h3>
                    <div class="form-flex form-adapt-2">
                      <div
                        class="form-group noMB"
                        :class="{ WRNG: Modal5.invalids.i6 }"
                      >
                        <b>Tip poziție catalog</b>
                        <v-select
                          :options="Modal5.types"
                          label="name"
                          v-model="Modal5.Input6"
                          :disabled="item.readonly"
                          :searchable="false"
                        />
                      </div>
                      <div class="form-group noMB">
                        <div style="display: flex; justify-content: space-between;">
                          <b>Denumire / Cod CPV</b>
                          <label class="switch" v-tooltip="{content: Modal5.dynamicSearch ? 'Activează modul static de căutare' : 'Activează modul dinamic de căutare'}">
                            <input v-model="Modal5.dynamicSearch" type="checkbox">
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div class="RForm">
                          <input
                            type="text"
                            @keydown.enter="Modal5.schCount++"
                            v-model="Modal5.search"
                            :disabled="!Modal5.Input6"
                            class="vInput"
                            placeholder="Cuvinte cheie"
                          />
                          <i @click="Modal5.schCount++" class="fas fa-search"></i>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  class="Popup_body_col"
                  style="
                    display: inline-flex;
                    flex-direction: column;
                    height: auto;
                  "
                >
                  <div
                    class="form-group colFlex noMB MTmobile1"
                    :class="{ WRNG: Modal5.invalids.i4 }"
                    style="flex-grow: 1"
                  >
                    <b style="line-height: 1.2">Descriere poziție de catalog</b>
                    <pre
                      class="mobileClampFontSize"
                      style="height: 100%; margin: 0; white-space: pre-wrap"
                      v-html="Modal5.Input4"
                    ></pre>
                  </div>
                </div>
              </div>
              <keep-alive v-if="!item.readonly">
                <catalog-select
                  v-show="Modal5.Input6"
                  :cpvType="Modal5.Input6"
                  :search="Modal5.search"
                  v-model="Modal5.Input2"
                  :onlyRead="false"
                  :searchCount="Modal5.schCount"
                  :dynamicSearch="Modal5.dynamicSearch"
                  @clearCpvSearch="() => (Modal5.search = '')"
                />
              </keep-alive>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button v-if="!item.readonly" @click="Modal5Save" class="Popup_footer_btn Footer_btn2">
                {{ item.modal === 5 && PERMISIONS.confirmProducts ? "Salvează" : item.modal === 5 ? "Trimite spre aprobare" : "Modifica" }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 5.2"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div>
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div
                v-if="item.productData && item.productData.name"
                class="Popup_subTitle"
              >
                {{ item.productData.name }}
              </div>
              <ul class="DeparmentDeleting_Parent" style="text-align: left">
                <li>
                  Denumire produs: <b>{{ item.productData.name }}</b>
                </li>
                <li>
                  Categorie CPV:
                  <ul>
                    <li>
                      Denumire categorie:
                      <b>{{
                        item.productData.cpvCategory &&
                        item.productData.cpvCategory.name
                          ? item.productData.cpvCategory.name
                          : "-"
                      }}</b>
                    </li>
                    <li>
                      Cod CPV:
                      <b>{{
                        item.productData.cpvCategory &&
                        item.productData.cpvCategory.cpvCode
                          ? item.productData.cpvCategory.cpvCode
                          : "-"
                      }}</b>
                    </li>
                  </ul>
                </li>
                <li>
                  Unitatea de măsură:
                  <ul>
                    <li>
                      Unitate de măsură:
                      <b>{{
                        item.productData.measurementUnit &&
                        item.productData.measurementUnit.shortName
                          ? item.productData.measurementUnit.shortName
                          : ""
                      }}</b>
                    </li>
                    <li>
                      Descriere:
                      <b>{{
                        item.productData.measurementUnit &&
                        item.productData.measurementUnit.description
                          ? item.productData.measurementUnit.description
                          : "-"
                      }}</b>
                    </li>
                  </ul>
                </li>
                <li>
                  Descriere produs:
                  <pre
                    style="margin: 0"
                    v-html="item.productData.description"
                  ></pre>
                </li>
              </ul>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button @click="delProduct" class="Popup_footer_btn Footer_btn3">
                Șterge
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 7 || item.modal === 7.1"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <div class="form-group" :class="{ WRNG: Modal7.invalids.i1 }">
                <b>Denumire sursă buget</b>
                <input
                  type="text"
                  v-model="Modal7.Input1"
                  class="vInput"
                  placeholder="Introduce denumirea sursei buget"
                />
                <!-- <small class="form-text text-muted">Introduce denumirea sursei buget</small> -->
              </div>
              <div class="form-group" :class="{ WRNG: Modal7.invalids.i2 }">
                <b>Descriere sursă buget</b>
                <input
                  type="text"
                  v-model="Modal7.Input2"
                  class="vInput"
                  placeholder="Introduce descrierea sursei buget"
                />
                <!-- <small class="form-text text-muted">Introduce descrierea sursei buget</small> -->
              </div>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button @click="Modal7Save" class="Popup_footer_btn Footer_btn2">
                {{ item.modal === 7 ? "Salveaza" : "Modifica" }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 7.2"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div>
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div
                v-if="item.budgetData && item.budgetData.name"
                class="Popup_subTitle"
              >
                {{ item.budgetData.name }}
              </div>
              <ul class="DeparmentDeleting_Parent" style="text-align: left">
                <li>
                  Denumire sursă buget: <b>{{ item.budgetData.name }}</b>
                </li>
                <li>
                  Descriere sursă buget:
                  <b>{{ item.budgetData.description }}</b>
                </li>
              </ul>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button @click="delBudget" class="Popup_footer_btn Footer_btn3">
                Sterge
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 8 || item.modal === 8.1"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <div class="form-group" :class="{ WRNG: Modal8.invalids.i1 }">
                <b>Denumire categorie cpv</b>
                <input
                  type="text"
                  v-model="Modal8.Input1"
                  class="vInput"
                  placeholder="Introduce denumirea"
                />
                <!-- <small class="form-text text-muted">Introduce denumirea</small> -->
              </div>
              <div class="form-group" :class="{ WRNG: Modal8.invalids.i2 }">
                <b>Cod CPV</b>
                <input
                  type="text"
                  v-model="Modal8.Input2"
                  class="vInput"
                  placeholder="Introduce codul cpv"
                />
                <!-- <small class="form-text text-muted">Introduce codul cpv</small> -->
              </div>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button @click="Modal8Save" class="Popup_footer_btn Footer_btn2">
                {{ item.modal === 8 ? "Salveaza" : "Modifica" }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 8.2"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div>
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div
                v-if="item.cpvCategoryData && item.cpvCategoryData.name"
                class="Popup_subTitle"
              >
                {{ item.cpvCategoryData.name }}
              </div>
              <ul class="DeparmentDeleting_Parent" style="text-align: left">
                <li>
                  Denumire categorie cpv: <b>{{ item.cpvCategoryData.name }}</b>
                </li>
                <li>
                  Cod CPV: <b>{{ item.cpvCategoryData.cpvCode }}</b>
                </li>
              </ul>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button
                @click="delCpvCategory"
                class="Popup_footer_btn Footer_btn3"
              >
                Sterge
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 11"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title" style="margin-top: 0.5rem">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <template v-if="item.rejectData">
                <div class="form-group">
                  <div
                    v-if="
                      !item.rejectData.approving && item.avizoMode === 'strict'
                    "
                    class="alert alert-danger"
                    role="alert"
                    style="margin: 1rem 0; padding: 1.5rem 2rem"
                  >
                    <i class="fas fa-exclamation-triangle"></i> În urma avizării
                    negative pe un flux
                    <b style="text-transform: uppercase">strict</b>, nevoia va
                    fi respinsă.
                  </div>
                  <b>{{ item.labels[0] }} de către</b>
                  <v-select
                    :value="
                      item.rejectData.rejectedByUser
                        ? item.rejectData.rejectedByUser
                        : ''
                    "
                    :disabled="true"
                    :no-drop="true"
                    :select-on-tab="false"
                    :clearable="false"
                  />
                </div>
                <div class="form-group">
                  <b>Data {{ item.labels[1] }}</b>
                  <v-select
                    :value="
                      item.rejectData.rejectedTime
                        ? item.rejectData.rejectedTime
                        : ''
                    "
                    :disabled="true"
                    :no-drop="true"
                    :select-on-tab="false"
                    :clearable="false"
                  />
                </div>
                <div class="form-group">
                  <b>{{ item.labels[2] }}</b>
                  <textarea
                    v-model="oneModelInput"
                    class="vInput"
                    width="100%"
                    cols=""
                    rows="10"
                  ></textarea>
                </div>
              </template>
              <template v-else>
                <div class="form-group">
                  <b>{{ item.labels[0] }} de către</b>
                  <v-select
                    :value="item.user || ''"
                    :disabled="true"
                    :no-drop="true"
                    :select-on-tab="false"
                    :clearable="false"
                  />
                </div>
                <div class="form-group">
                  <b>Data {{ item.labels[1] }}</b>
                  <v-select
                    :value="item.date || ''"
                    :disabled="true"
                    :no-drop="true"
                    :select-on-tab="false"
                    :clearable="false"
                  />
                </div>
                <div class="form-group">
                  <b>{{ item.labels[2] }}</b>
                  <textarea
                    v-model="oneModelInput"
                    class="vInput"
                    width="100%"
                    cols=""
                    rows="10"
                  ></textarea>
                </div>
              </template>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button
                @click="Modal11Save"
                class="Popup_footer_btn"
                :class="`Footer_btn${item.button.type}`"
              >
                {{ item.button.value }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 11.1"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-width: 217rem; max-height: 64em"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title" style="margin-bottom: -0.8rem">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
                <b
                  v-if="item.isCurrent"
                  style="margin-bottom: 1rem; font-size: 1.75rem"
                >
                  (Versiunea curentă)</b
                >
              </div>
              <div>
                <div
                  v-if="item.isCurrent"
                  class="NeedStatusLabel"
                  v-bind:data-type="
                    Modal11.statusLabelStyleMap[
                      item.data ? item.data.status : ''
                    ] || ''
                  "
                >
                  {{ statusNormalize(item.data ? item.data.status : "") }}
                </div>
                <div v-else class="NeedStatusLabel">Arhivat</div>
              </div>
              <need-history :Data="item.data" @Close="closeWindow()" />
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col"></div>
          </div>
        </div>
        <div
          v-if="item.modal === 13"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="padding-bottom: 0; max-width: 230rem; max-height: 65em"
        >
          <div
            @click="closeWindow"
            class="Popup_close_btn"
            style="z-index: 100;"
          >
            <img
              @load="imgLoaded"
              class="imgHide"
              style="margin: 2.2em;"
              src="../assets/media/vectors/close.svg"
              alt=""
            />
          </div>
          <div
            class="Popup_Files"
            :class="{
              MinimizedFiles: Modal13.openListMobile,
            }"
          >
            <div v-if="!Modal13.openListMobile">
              <div
                @click="Modal13.openListMobile = !Modal13.openListMobile"
                class="toggleMenuBtn"
              >
                <i class="fas fa-arrow-right"></i>
              </div>
            </div>
            <div
              v-if="item.fileList && Modal13.openListMobile"
              class="Popup_files_list"
            >
              <div>
                <div
                  v-for="(fileName, index) in item.fileList"
                  :key="index"
                  @click="changeNeedFile(index)"
                  v-tooltip="{
                    content: getNeedFileName(fileName),
                    delay: { show: 200 },
                  }"
                  class="Popup_files_list_item"
                  :class="Modal13.currentIndex == index ? 'FileSelected' : ''"
                >
                  <div class="Popup_files_list_item_ico">
                    <img
                      v-if="detectImage(fileName)"
                      src="../assets/media/image.svg"
                      alt=""
                    />
                    <img v-else src="../assets/media/file_black.svg" alt="" />
                  </div>
                  <div class="Popup_files_list_item_name">
                    {{ getNeedFileName(fileName) }}
                  </div>
                </div>
                <div
                  @click="Modal13.openListMobile = !Modal13.openListMobile"
                  class="toggleMenuBtn"
                >
                  <i class="fas fa-arrow-left"></i>
                </div>
              </div>
            </div>
            <div class="Popup_files_preview">
              <div v-if="!item.fileList" class="Popup_files_no_preview">
                <img
                  @load="imgLoaded"
                  class="imgHide"
                  style="transition-delay: 500ms"
                  src="@/assets/media/no_view.svg"
                  alt=""
                />
                <h2>Nu există fișiere atașate.</h2>
              </div>
              <template v-else>
                <div style="z-index: 99; position: relative">
                  <span>{{ getNeedFileName(Modal13.currentFile.name) }}</span>
                  <p>{{ Modal13.currentFile.size }}</p>
                </div>
                <transition-group name="popup">
                  <div
                    v-if="Modal13.currentIndex === null"
                    key="1"
                    class="Popup_files_fixed Popup_files_no_preview"
                  >
                    <img
                      @load="imgLoaded"
                      class="imgHide"
                      src="@/assets/media/file.svg"
                      alt=""
                    />
                    <h2>Alege un fișier</h2>
                  </div>
                  <div
                    v-else
                    key="2"
                    class="Popup_files_fixed Popup_files_placehold"
                    :style="
                      Modal13.currentFile.isPdf ? 'padding-right: 0;' : ''
                    "
                  >
                    <div class="Popup_file_main">
                      <div
                        class="Popup_files_placehold_loader"
                        :class="{ loader_active: Modal13.preloader }"
                      >
                        <img src="@/assets/media/preloader2.gif" alt="" />
                      </div>
                      <transition-group name="popup" style="display: flex; width: 90%; height: 100%; align-items: center; margin: auto; justify-content: center;">
                        <zoom-on-hover
                          v-if="
                            !Modal13.preloader &&
                            Modal13.base64 &&
                            !Modal13.currentFile.isPdf
                          "
                          key="1"
                          :src="Modal13.base64"
                          :zoomScale="1"
                        />
                        <span 
                          key="3"
                          v-else-if="
                            !Modal13.preloader &&
                            Modal13.currentFile.isPdf &&
                            Modal13.currentFile.isAdaptiveForPdfPreview
                          " 
                          style="width: 100%;"
                        >
                        <PdfViewer style="margin: 60px 20px;" :showClose="false" :pdfFile="Modal13.base64"></PdfViewer>

                        </span>
                        <div
                          v-else-if="!Modal13.preloader"
                          key="2"
                          @click="
                            () => {
                              if (Modal13.currentFile.isPdf) {
                                previewPdfForNeedFile();
                              }
                            }
                          "
                          :class="{
                            img_loaded: Modal13.imgLoading,
                            pointer: Modal13.currentFile.isPdf,
                          }"
                          class="imgHide Popup_isFile centerPreview"
                        >
                          <img
                            @load="imgLoaded"
                            class="imgHide"
                            src="@/assets/media/file.svg"
                            alt=""
                          />
                          <span
                            >Fișier {{ Modal13.currentFile.detectedType }}</span
                          >
                          <span
                            >.{{
                              Modal13.currentFile.name.split(".").pop()
                            }}</span
                          >
                        </div>
                      </transition-group>
                    </div>
                    <transition name="popup">
                      <div
                        v-show="
                          !(
                            Modal13.currentFile.isPdf &&
                            Modal13.currentFile.isAdaptiveForPdfPreview
                          ) && Modal13.imgLoading
                        "
                        class="Popup_file_footer"
                      >
                        <div
                          @click="downloadFile"
                          class="Button1 Btn_center Popup_file_btn"
                        >
                          <div class="icon">
                            <img
                              src="../assets/media/for_table/circle_ADD_new_etap.svg"
                              alt=""
                            />
                          </div>
                          <span class="Btn_content"> Descarcă </span>
                        </div>
                        <div
                          v-if="Modal13.currentFile.isPdf"
                          @click="previewPdfForNeedFile"
                          class="Button1 Btn_center Popup_file_btn"
                          style="padding-left: 1.8rem"
                        >
                          <div
                            class="icon"
                            style="margin-right: 0.8rem !important"
                          >
                            <i class="fas fa-eye"></i>
                          </div>
                          <span class="Btn_content"> Previzualizare PDF </span>
                        </div>
                      </div>
                    </transition>
                  </div>
                </transition-group>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 16"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-width: 100rem; max-height: 30em"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle" style="margin-bottom: 1rem">
                {{ item.subtitle }}
              </div>
              <div class="DeparmentDeleting_Parent">
                <ul style="text-align: left">
                  <li>
                    Denumire fișier: <b>{{ Modal13.currentFile.name }}</b>
                  </li>
                  <li>
                    Extensie fișier: <b>.{{ Modal13.currentFile.extension }}</b>
                  </li>
                  <li>
                    Dimensiune fișier: <b>{{ Modal13.currentFile.size }}</b>
                  </li>
                  <li>
                    Tip fișier: <b>{{ Modal13.currentFile.type }}</b>
                  </li>
                  <li>
                    Tipul detectat al fișierului:
                    <b>{{ Modal13.currentFile.detectedType || "-" }}</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button
                @click="deleteFile(true)"
                class="Popup_footer_btn Footer_btn3"
              >
                Sterge
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 14 || item.modal === 14.1"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-height: 45rem"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle" style="margin-bottom: 1rem">
                {{ item.subtitle }}
              </div>
              <div class="form-group">
                <b>Alege versiunea</b>
                <v-select
                  v-model="oneModelInput"
                  :options="Data.VERSIONS"
                  :loading="
                    emptyData.VERSIONS || Data.VERSIONS.length ? false : true
                  "
                  :searchable="false"
                  label="title"
                />
              </div>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button @click="Modal14Save" class="Popup_footer_btn Footer_btn2">
                Continuă
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 15"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-height: 55rem"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div
                class="InstitutionImgView internalPreloader"
                style="border-bottom: none"
              >
                <img @load="imgLoaded" :src="USER_AVATAR" alt="" />
              </div>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button class="Popup_footer_btn Footer_btn3">Sterge</button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 20 || item.modal === 20.1"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-width: 150rem"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <tabs :options="{ useUrlFragment: false }">
                <tab name="Detalii utilizator">
                  <users-create-form 
                    :Modal20="Modal20"
                    :checkerCount="Modal20.checkerCount"
                    :showResetBtn="item.modal === 20.1"
                    @requestPasswordReset="requestPasswordReset"
                  />
                </tab>
                <tab name="Salvează roluri">
                  <role-chooser
                    :error="Modal20.errorRoles"
                    v-model="Modal20.roles"
                  />
                </tab>
              </tabs>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Anuleaza
              </button>
            </div>
            <div class="Popup_footer_col">
              <button @click="Modal20Save" class="Popup_footer_btn Footer_btn2">
                {{ item.modal === 20 ? "Creeaza" : "Modifica" }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 20.2 || item.modal === 20.3"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-height: 55rem"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <keep-alive v-if="Modal20.showRolesDetails">
                <role-chooser
                  :showSelect="false"
                  :selectedRoles="item.data.roles"
                />
              </keep-alive>
              <ul
                v-else
                class="DeparmentDeleting_Parent"
                style="text-align: left; margin-bottom: 0.3rem"
              >
                <li>
                  Data creării: <b>{{ toDateAndTime(item.data.createdAt) }}</b>
                </li>
                <li>
                  CNP: <b>{{ item.data.cnp || "-" }}</b>
                </li>
                <li>
                  Username: <b>{{ item.data.email || "-" }}</b>
                </li>
                <template v-if="item.modal === 20.3">
                  <li>
                    E-mail de corespondență:
                    <b>{{ item.data.emailForCommunication || "-" }}</b>
                  </li>
                  <li>
                    Nume: <b>{{ item.data.first_name || "-" }}</b>
                  </li>
                  <li>
                    Prenume: <b>{{ item.data.last_name || "-" }}</b>
                  </li>
                  <li>
                    ID / ConectX: <b>{{ item.data.id || "-" }}</b>
                  </li>
                  <li>
                    Telefon: <b>{{ item.data.phone || "-" }}</b>
                  </li>
                </template>
              </ul>
              <div
                style="text-align: right"
                :style="
                  Modal20.showRolesDetails
                    ? 'border-top: 1px solid #c3c3c3;margin-top: 0.6rem;'
                    : ''
                "
              >
                <a
                  v-if="item.modal === 20.3"
                  @click.prevent="
                    Modal20.showRolesDetails = !Modal20.showRolesDetails
                  "
                  href="#"
                  style="margin: 0"
                  ><i class="fas fa-user-tag" style="margin-right: 0.5rem"></i
                  >{{ !Modal20.showRolesDetails ? "Vezi" : "Ascunde" }} rolurile
                  utilizatorului</a
                >
              </div>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button
                v-if="item.modal === 20.2"
                @click="deleteUser"
                class="Popup_footer_btn Footer_btn3"
              >
                Sterge
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="item.modal === 23"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-height: 85rem; max-width: 77em"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%; display: flex; flex-direction: column">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <users-history :versions="item.versions" />
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col"></div>
          </div>
        </div>
        <div
          v-if="item.modal === 24"
          @click.stop=""
          :id="item.ID"
          class="Popup_Inner"
          style="max-width: 100rem; max-height: 45em;"
        >
          <div class="Popup_Inner_main">
            <div @click="closeWindow" class="Popup_close_btn">
              <img
                @load="imgLoaded"
                class="imgHide"
                src="../assets/media/vectors/close.svg"
                alt=""
              />
            </div>
            <div style="width: 100%">
              <div class="Popup_title">
                {{ item.title }}
              </div>
              <div class="Popup_subTitle">
                {{ item.subtitle }}
              </div>
              <div>
                <div class="Popup_body_cols">
                  <div class="Popup_body_col">
                    <div class="form-group" :class="{ WRNG: Modal9.invalids.i2 }">
                      <b>Valoarea minimă (Lei)</b>
                      <inp-number v-model="Modal9.Input2" />
                    </div>
                    <div class="form-group" :class="{ WRNG: Modal9.invalids.i1 }">
                      <b>Valoarea maximă (Lei)</b>
                      <inp-number v-model="Modal9.Input1" />
                    </div>
                    <div class="form-group" :class="{ WRNG: Modal9.invalids.i3 }">
                      <b>Categoria achiziției</b>
                      <v-select
                        :options="Modal9.achizitionCategory"
                        :searchable="false"
                        v-model="Modal9.Input3"
                      >
                        <template #option="{ label }">
                          <span class="v-select_li_custom">
                            <span>{{ label }}</span>
                            <i v-tooltip="label" class="fas fa-info-circle"></i>
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div class="Popup_body_col">
                    <div class="form-group" :class="{ WRNG: Modal9.invalids.i5 }">
                      <b>Valoarea minimă (Eur)</b>
                      <inp-number v-model="Modal9.Input5" />
                    </div>
                    <div class="form-group" :class="{ WRNG: Modal9.invalids.i4 }">
                      <b>Valoarea maximă (Eur)</b>
                      <inp-number v-model="Modal9.Input4" />
                    </div>
                    <div class="form-group" :class="{ WRNG: Modal9.invalids.i6 }">
                      <b>Tipul achiziției</b>
                      <v-select-infinite
                        v-model="Modal9.Input6"
                        label="name"
                        :startup="true"
                        :apiModule="procedureTypePaginated"
                        :overflowPrevent="true"
                        :appendToBody="true"
                      >
                      </v-select-infinite>
                    </div>
                  </div>
                </div>
                <div class="form-group" :class="{ WRNG: Modal9.invalids.i7 }">
                  <b>Tipul instituției</b>
                  <v-select
                    :options="Modal9.institutionType"
                    :searchable="false"
                    v-model="Modal9.Input7"
                  >
                    <template #option="{ label }">
                      <span class="v-select_li_custom">
                        <span>{{ label }}</span>
                        <i v-tooltip="label" class="fas fa-info-circle"></i>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="Popup_footer">
            <div class="Popup_footer_col">
              <button @click="closeWindow" class="Popup_footer_btn Footer_btn1">
                Inapoi
              </button>
            </div>
            <div class="Popup_footer_col">
              <button
                @click="Modal24Save()"
                class="Popup_footer_btn Footer_btn2"
              >
              {{ item.modal === 24 ? "Salveaza" : "Modifica" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<style src="@/assets/popup.css"></style>
<style>
.noMB {
  margin-bottom: 0 !important;
}
</style>

<script>
import PermisionsMixin from "@/mixins/allPermMixin.js";
import updateRoleStatus from '../mixins/updateUserRoleStatus.js'
import FileManager from "@/mixins/fileManager.js";
import entityReservationMixin from "@/mixins/reserveForEdit.js";
import {
  EXPENSES,
  PROJECTS,
  NEED_RESERVATION,
  NEEDS_DOWNLOAD_FILE,
  NEEDS_VERSIONS,
  NEEDS_ADD,
  NEEDS_MODIFY_FORMDATA,
  NEEDS_DELETE,
  NEEDS_ONCE,
  NEEDS_PAGINATED,
  DEPARTMENT,
  PRODUCTS,
  PRODUCTS_ONCE,
  PRODUCTS_ADD,
  PRODUCTS_MODIFY,
  PRODUCTS_DELETE,
  PRODUCTS_DELETE_REJECTED,
  MEASUREMENT_UNIT,
  MEASUREMENT_UNIT_ONCE,
  BUDGET,
  INSTITUTION,
  INSTITUTIONS,
  CPV_CATEGORY_ADD,
  CPV_CATEGORY_MODIFY,
  CPV_CATEGORY_DELETE,
  CPV_CATEGORY_ONCE,
  BUDGET_ONCE,
  BUDGET_ADD,
  BUDGET_MODIFY,
  BUDGET_DELETE,
  PROCEDURE_TYPE,
  PROCEDURE_TYPE_ONCE,
  USERS_ORIGIN,
  USERS_ONCE,
  USERS_ADD,
  USERS_ADD_ROLE,
  USERS_DELETE,
  USER_MODIFY,
  ACTIVATE_ROLE,
  EDIT_ROLE,
  AVIZO,
  REQUEST_PASSWORD_RESET,
  REGISTRU_MODIFY,
  PRODUCTS_CAN_EDIT_CPV,
  ACHIZITII,
  USERS_FROM_CURENT_USER_DEPARTMENT,
  PRODUCTS_PAGINATED,
  PROCEDURE_TYPE_PAGINATED,
  REGISTRU_ADD,
  CURRENCY,
  ACQUISITIONS,
  VERBAL_PROCESS
} from "../api.js";

import { mapGetters, mapMutations } from "vuex";
import FilesAgent from "@/components/FilesAgent";
import DialogModal from "@/components/DialogModal";
import PdfViewer from "@/components/PdfViewer.vue";


const getSessPass = () => {
  let x = sessionStorage.getItem("passwordReset");
  return x ? JSON.parse(x) : [];
};
const savePassSession = (email) => {
  const x = getSessPass();
  if (!x.find((e) => e === email)) {
    x.push(email);
  }
  sessionStorage.setItem("passwordReset", JSON.stringify(x));
};
const checkPassSession = (email) => {
  const x = getSessPass();
  return !!x.find((e) => e === email);
};

const numeral = require("numeral");
const saver = require("file-saver");
function getDefaultData() {
  return {
    Data: {
      productData: [],
      proposalData: [],
      muData: [],
      institutionData: [],
      departmentData: [],
      budgetSourcesData: [],
      cpvCategories: [],
      procedureTypeData: [],
      VERSIONS: [],
      needDesc: [],
      achizitii: [],
    },
    Modal1: {
      temporar: false,
      editorKey: 0,
      invalids: {
        i1: false,
        i2: false,
        i3: false,
        i5: false,
        files: false,
        i7: false,
        i8: false,
        i9: false,
        i10: false,
        i11: false,
        i12: false,
        i13: false,
        i14: false,
        i15: false,
        isSocial: false,
        cotaTva: false
      },
      cotaTva: "",
      Input1: "",
      Input2: "",
      Input3: "",
      Input5: "",
      Input6: [],
      Input7: "",
      achizitionFromProduct: [],
      //achizitionCategory: null,
      Input8: "",
      Input9: [],
      selectedAvizo: [],
      Input10: "",
      isSocial: false,
      socialTypes: [
        {
          label: "Social",
          isSocial: true,
          id: 0,
        },
        {
          label: "Non-social",
          isSocial: false,
          id: 1,
        },
      ],
      Input11: null,
      Input12: null,
      externalAvizo: [],
      users: [],
      files: [],
      isDisabled: false,
      revisionState: false,
      approveState: false,
      canAvizo: false,
      theCharacteristics: null,
      paap: [false, false, false, false, false, false, false],
      showPapp: false,
      describeTheCharacteristics: [
        {
          name: "prin raportare la cerinţe de performanţă/funcţionale",
          id: 0,
        },
        {
          name: 'prin trimitere la specificaţii tehnice însoţită de menţiunea "sau echivalent"',
          id: 1,
        },
        {
          name: "prin raportare la cerinţe de performanţă/funcţionale şi prin trimitere la specificaţii",
          id: 2,
        },
        {
          name: "prin trimitere la specificaţiile pentru unele caracteristici şi prin raportare la cerinţe de performanţă/funcţionale pentru alte caracteristici",
          id: 3,
        },
      ],
      revisionStates: [
        {
          title: "Strict",
          desc: '<ul style="padding:0rem 0 0 2rem;margin:0;text-align: left;"><li>toți avizatorii își prezintă avizul (pozitiv sau negativ - nu conteaza) - obligatoriu - daca cineva respinge, nevoia se intoarce la titular</li><li>ordinea avizelor trebuie respectata - ex. avizatorul 3 nu primește cererea de avizare pană când avizatorul 2 nu și-a dat avizul.</li><li>aprobatorul nu poate aproba înainte de prezentarea tuturor avizelor</li></ul>',
          id: 0,
        },
        {
          title: "Flexibil",
          desc: '<ul style="padding:0rem 0 0 2rem;margin:0;text-align: left;"><li>toți avizatorii își prezintă avizul (pozitiv sau negativ - nu conteaza) - obligatoriu - daca cineva respinge, nu mai este nevoie sa intoarcem nevoia</li><li>ordinea avizelor trebuie respectata - ex. avizatorul 3 nu primește cererea de avizare pană când avizatorul 2 nu și-a dat avizul.</li><li>aprobatorul nu poate aproba înainte de prezentarea tuturor avizelor</li></ul>',
          id: 1,
        },
        {
          title: "Facultativ",
          desc: '<ul style="padding:0rem 0 0 2rem;margin:0;text-align: left;"><li>se poate aproba oricand, nu tinem cont neaprat de fluxul de avizare</li><li>se paote aviza in orice ordine</li></ul>',
          id: 2,
        },
      ],
      approveMode: false,
    },
    Modal2: {
      selectedNeed: 0,
    },
    Modal5: {
      invalids: {
        i1: false,
        i2: false,
        i3: false,
        i4: false,
        i5: false,
        i6: false,
        i7: false,
      },
      Input1: "",
      Input2: "",
      Input3: "",
      Input4: "",
      Input5: "",
      Input6: "",
      Input7: "",
      Input8: "",
      search: "",
      achizitionCategories: [],
      schCount: 0,
      types: [
        { name: "Produse", id: 1 },
        { name: "Servicii", id: 2 },
        { name: "Lucrări", id: 3 },
      ],
      productStatus: false,
      dynamicSearch: false
    },
    Modal7: {
      invalids: {
        i1: false,
        i2: false,
      },
      Input1: "",
      Input2: "",
    },
    Modal8: {
      invalids: {
        i1: false,
        i2: false,
      },
      Input1: "",
      Input2: "",
    },
    Modal11: {
      avizo: [],
      show: false,
      mode: false,
      viewDesc: false,
      reasonIndex: -1,
      statusLabelStyleMap: {
        pending: 1,
        approved: 2,
        rejected: 3,
        avizo: 4,
        editing: 5,
        approvalReady: 6,
        asteptare_editare_in_urma_anularii_de_catre_un_administrator: 7,
        cancelled: 8,
      },
    },
    Modal13: {
      files: [],
      currentIndex: null,
      currentFile: { name: "" },
      preloader: true,
      imgLoading: false,
      loadingAjax: false,
      openListMobile: true,
    },
    Modal20: {
      invalids: {
        i1: false,
        i2: false,
        i3: false,
        i4: false,
        i5: false,
        i6: false,
        i7: false,
        i8: false,
      },
      Input1: "",
      Input2: "",
      Input3: "",
      Input4: "",
      Input5: "",
      Input6: "",
      p1: "",
      usernameIsOk: false,
      cnpIsOk: false,
      emailIsUnique: false,
      roles: [],
      errorRoles: false,
      showRolesDetails: false,
      fetchIntervals: [
        [null, 0, ""],
        [null, 0, ""],
      ],
      showPassword: false,
      phoneStatus: null,
      checkerCount: 0,
      uuid: "",
    },
    Modal9: {
      invalids: {
        i1: false,
        i2: false,
        i3: false,
        i4: false,
        i5: false,
        i6: false,
        //i7: false,
      },
      Input1: "",
      Input2: "",
      Input3: "",
      Input4: "",
      Input5: "",
      Input6: "",
      Input7: "",
      institutionType: ["Administrație publică locală", "Administrație publică centrală"],
      achizitionCategory: ["Produse", "Servicii", "Lucrări" ]
    },
    emptyData: {
      departments: false,
      institutions: false,
      muData: false,
      budgetData: false,
      procedureType: false,
      cpvCategory: false,
      productData: false,
      proposalData: false,
      VERSIONS: false,
      needUsers: false,
      externalAvizo: false,
      achizitii: false,
    },
  };
}
function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}
let CatalogSelectModule = null;
let needHistoryViewerModule = null;
const blockFromBgClickMap = [1, 5]

let stopWatcher = false;

export default {
  mixins: [PermisionsMixin, updateRoleStatus, entityReservationMixin, FileManager],
  components: {
    "zoom-on-hover": () => {
      import("vue-inner-image-zoom/lib/vue-inner-image-zoom.css");
      return import("vue-inner-image-zoom");
    },
   /*  "vue-pdf-app": () => {
      import("vue-pdf-app/dist/icons/main.css");
      return import("vue-pdf-app");
    }, */
    "files-manager": FilesAgent,
    "role-chooser": () => import("./UserCreateRoleChooser"),
    "catalog-select": () => CatalogSelectModule || import("./CatalogSelect"),
    "need-history": () =>
      needHistoryViewerModule || import("./needHistoryViewer"),
    "users-history": () => import("./UsersHistoryModal"),
    draggable: () => import("vuedraggable"),
    'users-create-form': () => import('@/components/UserCreateForm.vue'),
    PdfViewer,
  },
  data() {
    const d = getDefaultData();
    return {
      watchers: [],
      onModalCloseFn: [],
      dropdownScrollInterval: null,
      PERMISIONS: {},
      Data: d.Data,
      Modal1: d.Modal1,
      Modal2: d.Modal2,
      Modal5: d.Modal5,
      Modal7: d.Modal7,
      Modal8: d.Modal8,
      Modal9: d.Modal9,
      Modal11: d.Modal11,
      Modal13: d.Modal13,
      Modal20: d.Modal20,
      emptyData: d.emptyData,
      oneModelInput: "",
      search: d.search,
      selectIsEmpty: false,
      products: [],
      cpvType: {
        1: 'Produse',
        2: 'Servicii',
        3: 'Lucrări'
      }
    };

  },
  computed: {
    ...mapGetters([
      "Window",
      "userRole",
      "getProductData",
      "getNeedsTableData",
      "getDepartmentData",
      "USER_AVATAR",
    ]),

    procedureTypePaginated( ) {
      return PROCEDURE_TYPE_PAGINATED
    },  
    achizitionsSelectModule() {
      return () => import('@/components/CatalogAchizitionsSelect')
    },

    filteredNeedApprover() {
      const role = this.getUserRole(true, true);
      return this.Modal1.Input9.filter(el => el.departmentId == role.departmentId && el.role == 'supervisor')
    },

    filteredNeedAvizo() {
      return this.Modal1.Input9.filter(
        (e) => e.uuid !== NaN && this.Modal1.Input12?.label != e.label && !this.Modal1.selectedAvizo.some((x) => x.uuid == e.uuid)
      );
    },
    filteredNeedExternalAvizo() {
      const x = this.Modal1.Input12?.uuid;
      return this.Modal1.externalAvizo
        .filter(
          (e) =>
            e.user?.uuid != x &&
            !this.Modal1.selectedAvizo.some((x) => x.id == e.id)
        )
        .map((e) =>  {
          const label =  `${this.readUserObject(e.user)} — ${e.institution?.name}`;

          if (e.department) {
            label = label + `— ${e.department?.name}`;
          }

          return {
          // ...e,
          __isExternal: true,
          uuid: e.user?.uuid || null,
          id: e.id ?? null,
          label,
        }});
    },
    convertedMuData() {
      return this.Data.muData.map((e) => ({
        id: e.id,
        name: `${e.shortName} — „${e.description}”`,
      }));
    },
    departmentsFromInstitution() {
      if (!this.Window.length) {
        return [[]];
      }
      if (!this.isSuperadmin) {
        return [this.Data.departmentData, true];
      }
      if (this.getWindow().modal >= 2 && this.getWindow().modal < 3) {
        if (!this.Data.institutionData.length || !this.Modal2.Input6) {
          return [[]];
        }
        const filtered = this.Data.departmentData.filter(
          (dep) => dep.institution == this.Modal2.Input6.id
        );
        return [filtered, filtered.length ? false : true];
      }
      this.Modal1.Input8 = "";
      if (!this.Data.institutionData.length || !this.Modal1.Input7) {
        return [[]];
      }
      const filtered = this.Data.departmentData.filter(
        (dep) => dep.institution == this.Modal1.Input7.id
      );
      return [filtered, filtered.length ? false : true];
    },
    userPasswordStrength() {
      const strongPasswordRegex = new RegExp(
        "^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$",
        "g"
      );
      const mediumPasswordRegex = new RegExp(
        "^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
        "g"
      );
      const enoughPasswordRegex = new RegExp("(?=.{8,}).*", "g");

      const x = this.Modal20.p1;
      if (!x) return false;
      if (strongPasswordRegex.test(x)) return 3;
      if (mediumPasswordRegex.test(x)) return 2;
      if (enoughPasswordRegex.test(x)) return 1;
      return 0;
    },
  },
  methods: {
    ...mapMutations({
      addWindow: "addWindow",
      del: "delWindow",
      setProductData: "setProductData",
      setDepartmentData: "setDepartmentData",
      setIdForPreview: "setReferenceIdForPreview",
    }),

    onFocusOutModal5Input1(e) {
      if(e.key == 'Tab' )   {
        this.products = []
      }

      setTimeout(() => { this.products = [] }, 300)
    },
    prepareSearch(x) {
     return ["columnsOr", `name||${x}`]
    },
    handleChangeModal5Input1(x) {
      this.findProductsByName(x)

    },
    findProductsByName(x) {
      if (!x) {
        this.products = [];
        return;
      }

      PRODUCTS_PAGINATED(1, 500, null, `name=${x}`).then(res => {
        this.products = res.data.result;
      }).catch(res => {})
    },

    onClickProduct(item) {
      this.Modal5.Input1 = item.name
      this.products = []
    },

    saveNeedDesc() {
      const x = this.Modal2.selectedNeed;
      if (x > -1) {
        const find = this.Data.needDesc[x];
        if (find?.technicalDescription) {
          this.Modal1.Input10 = find.technicalDescription;
          this.Modal1.invalids.i10 = false;
          this.Modal1.editorKey++;
        } else {
          this.$toastr.e("Încercați mai târziu");
        }
        stopWatcher = true;
        this.closeWindow();
      }
    },
    viewEditingNeedFiles() {
      const modal = this.getWindow();
      this.addWindow({
        itemId: this.getWindow()?.needId,
        fileList: this.Modal1.files.map(e => e.keyFilename),
        canDelete: false,
        modal: 13
      })
    },
    selectDescNeed(index) {
      if (Number.isInteger(index) && index != this.Modal2.selectedNeed) {
        this.Modal2.selectedNeed = index;
      } else {
        this.Modal2.selectedNeed = -1;
      }
    },
    openNeedDesc() {
      if (this.Modal1.Input1) {
        this.addWindow({
          modal: 2,
        });
      }
    },
    addProduct() {
      const el = this.$refs.productsCatalog;
      if (el) {
        const findId = el[0].$el.id;
        if (findId) {
          const findInput = document.querySelector(
            `#${findId} input[type=search]`
          );
          if (findInput) {
            this.Modal5.Input1 = this.uppercaseFirst(findInput.value) || "";
          }
        }
      }
      this.addWindow({
        title: "Adaugă Poziție de catalog",
        fromNeedFlow: true,
        modal: 5,
      });
    },
    reasonManager(index) {
      if (!Number.isInteger(index)) return;
      this.Modal11.reasonIndex =
        index === this.Modal11.reasonIndex ? -1 : index;
    },
    viewAvizo(reload) {
      this.Modal11.show = true;
      if (reload) {
        this.Modal11.avizo.splice(0);
      }
      if (!this.Modal11.avizo.length) {
        const x = this.getWindow();

        AVIZO(x?.data.needData?.id)
          .then((res) => {
            if (!Array.isArray(res?.data?.result)) {
              this.$toastr.e("Răspunsul nu a fost primit.");
              this.Modal11.show = false;
              return;
            }
            if (
              !res.data.result.length ||
              !res.data.result[0].avizatorii?.length
            ) {
              this.$toastr.w("Avizatorii nu au fost găsiți.");
              this.Modal11.show = false;
              return;
            }
            this.Modal11.mode = res.data.result[0].avizoMode;

            const data = this.isString(res.data.result[0].avizatorii)
              ? JSON.parse(res.data.result[0].avizatorii)
              : res.data.result[0].avizatorii;
            const currentAvizoIndex =
              this.Modal11.mode != "Facultativ"
                ? data.findIndex((e) => e.action == "undefined")
                : -1;

            const avizoActionsMap = {
              approved: "Aviz pozitiv",
              rejected: "Aviz negativ",
            };
            this.Modal11.avizo = data
              .map((e, i) =>
                Number.isInteger(e.user?.id)
                  ? {
                      ...e,
                      name: `${e.user.last_name} ${e.user.first_name} / ${e.user.id}`,
                      curr: i === currentAvizoIndex,
                      showMore: false,
                      action:
                        data[i].action != "undefined"
                          ? avizoActionsMap[data[i].action] || data[i].action
                          : currentAvizoIndex > -1 && i !== currentAvizoIndex
                          ? "În așteptare"
                          : "<i>Urmează să avizeze</i>",
                      reason:
                        data[i].reason && data[i].reason != "No reason provided"
                          ? data[i].reason.trim()
                          : false,
                    }
                  : false
              )
              .filter((e) => e);
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.Modal11.show = false;
          });
      }
    },
    getWindow() {
      return this.Window[this.Window.length - 1];
    },
    selectedUserNeed(option, unselect) {
      if (unselect) {
        this.Modal1.selectedAvizo = this.Modal1.selectedAvizo.filter(
          (e) => e.id !== option.id
        );
      } else {
        this.Modal1.selectedAvizo.unshift(option);
      }
    },
    async getNeedUsers() {
      if (!this.Modal1.Input9.length) {
        await USERS_ORIGIN().then((data) => {
          const Uuid = this.getUuid();
          // externalAvizo
       if (Array.isArray(data?.data?.externalApproveBy) && data.data.externalApproveBy.length) {
            this.Modal1.externalAvizo = data.data.externalApproveBy
          } else this.emptyData.externalAvizo = true; 

          const x = data?.data?.result.filter((e) => e.uuid != Uuid);

          if (Array.isArray(!x)) {
            this.Modal1.Input9.push(false);
            return;
          }
          if (!x.length) {
            this.emptyData.needUsers = true;
            return;
          }

          const prepared = x.flatMap((x) =>
            x.userRoles
              .filter((e) => e?.role !== "admin_institutie")
              .map((e) => ({
                id: e.id,
                uuid: x.uuid,
                last_name: x.last_name,
                first_name: x.first_name,
                departmentId: e.department?.id,
                role: e.role,
                label: `${x.last_name} ${x.first_name} / ${x.id} ${
                  e.department?.name ? `— ${e.department.name}` : ""
                } — ${this.changeRoleView(e?.role)}`,
              }))
          );

          this.Modal1.Input9 = prepared;

          this.Modal1.users = x;
        });
      }
    },
    viewFilesFromNeedVersion() {
      const popup = this.getWindow();

      this.addWindow({
        fileList:
          Array.isArray(popup.data.needData.attachedDocuments) &&
          popup.data.needData.attachedDocuments.length
            ? popup.data.needData.attachedDocuments
            : null,
        itemId: popup.data.needData.id,
        canDelete: false,
        modal: 13,
      });
    },
    prepareDestionationYearsForNeeds() {
      const years = [];
      const curr = new Date().getFullYear();
      years.push(curr, curr + 1);
      return years;
    },
    previewPdfForNeedFile() {
      if (this.stopPreviewPdf) return;
      this.stopPreviewPdf = true;
     // this.$root.$el?.classList.add("__Loading");
      this.setIdForPreview({
        origin: "readPdf",
        data: this.Modal13.base64,
      });
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          this.stopPreviewPdf = false;
        });
      });
    },
    deleteFile(confirm) {
      if (!confirm) {
        this.addWindow({
          modal: 16,
          title: "Ștergere fișier",
          subtitle: "Ești sigur că vrei să ștergi fișierul selectat?",
        });
        return;
      }
      if (this.Window.length > 1) {
        this.del();
      }
      const data = this.getWindow();
      const popup = this.Modal13;
      const currentIndex = popup.currentIndex;
      const findElement = popup.files.find((e) => e.index == currentIndex);

      if (
        data.itemId != undefined &&
        Array.isArray(data.fileList) &&
        findElement.name
      ) {
        const newList = data.fileList.filter((e) => e != findElement.name);
        data.fileList = newList;

        NEEDS_MODIFY_FORMDATA(data.itemId, { attachedDocuments: newList })
          .then((data) => {
            if (
              (data.status && String(data.status) !== "2") ||
              (data.statusCode && String(data.statusCode) !== "2") ||
              (data &&
                data.meta &&
                data.meta.HttpStatusCode &&
                String(data.meta.HttpStatusCode)[0] !== "2")
            ) {
              this.$toastr.e("Încercați mai târziu");
              this.closeWindow();
              return;
            }
            this.getWindow().fileList = newList.length ? newList : null;
            popup.currentIndex = null;
            popup.currentFile = { name: "" };
            popup.files = popup.files.filter((e) => e.name != findElement.name);
            this.$toastr.s("Fișierul selectat a fost șters.");
            this.$emit("Needs", "reload");
          })
          .catch((err) => {
            this.$toastr.e(err);
          });
      } else {
        this.$toastr.e(
          "Anunțați administratorul sau încercați mai târziu."
        );
      }
    },
    downloadFile() {
      const popup = this.Modal13;
      const currentIndex = popup.currentIndex;
      const findElement = popup.files.find((e) => e.index == currentIndex);

      saver.saveAs(findElement.file);
    },
    changeFilePreview() {
      const popup = this.Modal13;
      const currentIndex = popup.currentIndex;
      const findElement = popup.files.find((e) => e.index == currentIndex);
      popup.preloader = true;
      popup.imgLoading = false;

      if (!findElement) {
        popup.preloader = true;
        popup.imgLoading = false;
        return;
      }
      setTimeout(() => {
        popup.currentFile = Object.assign(
          { isAdaptiveForPdfPreview: window.innerWidth > 1040 },
          findElement
        );
        popup.base64 = findElement.base64;
        window.requestAnimationFrame(() => {
          popup.imgLoading = true;
        });

        popup.preloader = false;
      }, 300);
    },
    detectImage(name) {
      const imgExtensions = [
        "png",
        "jpg",
        "jpeg",
        "gif",
        "bmp",
        "eps",
        "tif",
        "tiff",
      ];
      if (imgExtensions.includes(String(name.split(".").pop()).toLowerCase())) {
        return true;
      }
      return false;
    },
    async changeNeedFile(index) {
      const data = this.getWindow();
      const popup = this.Modal13;

      if (!data.fileList || popup.loadingAjax) return;
      const selectedFileName = data.fileList[index];
      const fileExtension = String(
        selectedFileName.split(".").pop()
      ).toLowerCase();

      if (popup.files.find((e) => e.name == selectedFileName)) {
        popup.currentIndex = index;
        this.changeFilePreview();
        return;
      }
      const fileMap = {
        Word: ["doc", "docm", "docx", "docx"],
        Excel: ["xlsx", "xlsm", "xlsb", "xltx"],
        PowerPoint: ["pptx", "pptm", "ppt"],
        TIFF: ["tif", "tiff"],
        GIF: ["gif"],
        Bitmap: ["bmp"],
        PDF: ["pdf"],
        Image: ["png", "jpg", "jpeg"],
      };
      popup.currentIndex = "(none)";

      const findExtension = Object.entries(fileMap).find((e) =>
        e[1].includes(fileExtension)
      );
      if (findExtension) {
        popup.detectedType = findExtension[0];
      } else {
        popup.detectedType = "";
      }

      const fileModules = {
        EXPENSES,
        PROJECTS,
        ACQUISITIONS,
        VERBAL_PROCESS
      };


      this.changeFilePreview();
      popup.loadingAjax = true;

      const formatFile = async (file) => {
          // if (!file || !file.type || file.type == "application/json") {
          //   this.$toastr.Add({
          //     msg: "Fișierul selectat a fost obținut cu greșeli.",
          //     timeout: 10000,
          //     type: "error",
          //   });
          // }
          let size = "";
          if (file.size != undefined) {
            size = numeral(file.size).format("0.0 b");
          }

          const prepared = new File([file], selectedFileName, {
            type: "text/plain;charset=utf-8",
          });
          let base64 = "";
          if (
            fileExtension === "pdf" ||
            file.type.toLowerCase().includes("image")
          ) {
            base64 = await this.readAsDataUrl(file);
          }
          popup.files.push({
            name: selectedFileName,
            size: size,
            type: file.type,
            isPdf: fileExtension === "pdf",
            detectedType: popup.detectedType,
            extension: fileExtension,
            file: prepared,
            blob: file,
            base64: base64,
            index: index,
          });
          popup.loadingAjax = false;
      }
    if (!data.isBlob) {
      await (data.searchModuleApi || (data.searchModule && fileModules[data.searchModule]
        ? fileModules[data.searchModule].download
        : NEEDS_DOWNLOAD_FILE))(
        this.isObject(data.itemId)
          ? data.itemId[selectedFileName]
          : data.itemId,
        selectedFileName
      )
        .then(async (file) => {
          await formatFile(file)
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
        });
      } else {
        await formatFile(data.isBlob)
      }
      popup.currentIndex = index;
      this.changeFilePreview();
    },
    Product() {
      const el = this.$refs.productsCatalog;
      if (el) {
        const findId = el[0].$el.id;
        if (findId) {
          const findInput = document.querySelector(
            `#${findId} input[type=search]`
          );
          if (findInput) {
            this.Modal5.Input1 = findInput.value || "";
          }
        }
      }
      this.addWindow({
        title: "Adaugă Poziție de catalog",
        modal: 5,
      });
    },
    toPriceFormat(string) {
      return window.$toPriceFormat(string);
    },
    toDate(string) {
      return window.$toDate(string);
    },
    closeWindowWithStopPrevent() {
      stopWatcher = true;
      this.closeWindow();
    },
    closeWindow(checkLoading, checkBlocking) {
      const MODAL = this.getWindow() || {}
      
      if(checkBlocking && blockFromBgClickMap.includes(+String(MODAL.modal).split('.')[0])) {
        return
      }
      if (
        checkLoading &&
        document
          .getElementById(MODAL.ID)
          ?.classList?.contains("Popup_loading")
      ) {
        return;
      }
      const d = getDefaultData();
      let modal = Math.floor(MODAL.modal);
      const stopWatcherMap = [2, 11, 13];
      const convertor = {
        24: 9,
      };
      if (stopWatcherMap.includes(modal)) {
        stopWatcher = true;
      } else {
        modal = convertor[modal] || modal;
      }

      this.productsListPerPage = 20;
      this.productsListHistory = [];
      if (this.Window.length === 1) this.Data = d.Data;
      if (this[`Modal${modal}`]) {
        this[`Modal${modal}`] = d[`Modal${modal}`];
      }

      this.emptyData = d.emptyData;
      this.oneModelInput = "";
      this.del();
      this.watchers.forEach((e) => (e[1] === modal ? e[0]() : false));
      this.watchers = [];
      this.setLoad();

      this.stopReserve()
      this.onModalCloseFn.forEach(e => typeof e == 'function' ? e() : false)
      this.onModalCloseFn.length = 0

      if(typeof MODAL.onClose == 'function') {
        MODAL.onClose()
      }
      document.getElementsByTagName('html')[0].style.overflow = ''

    },
    delFile(fileIndex) {
      if (fileIndex !== 0 && !parseInt(fileIndex)) return;

      const data = this.Modal1.files.filter((e) => !e.deleted);
      data.splice(
        fileIndex,
        1,
        Object.assign({ deleted: true }, this.Modal1.files[fileIndex])
      );
      this.Modal1.files = data;
    },
    removeWarningClass(ev) {
      ev.target?.classList.remove("Produs_warning");
    },
    async getProducts() {
      let x = await PRODUCTS();
      if (x?.data?.result?.length) {
        const data = this.getProductData;
        x = x.data.result;
        x.forEach((product) => {
          product.productCpvCode = product.cpvCategory?.cpvCode || "-";
          product.cpvName = product.cpvCategory?.name || "-";
          product.measurementUnit = product.measurementUnit?.shortName || "-";
        });
        this.setProductData(data.concat(x));
        return x;
      } else {
        this.emptyData.productData = true;
      }
    },
    async getAchizitii() {
      let x = await ACHIZITII.get(1, 2000);
      if (
        x?.data?.result
      ) {
        if(!x.data.result.length) {
          this.emptyData.achizitii = true
        }
        return x.data.result;
      }
    },
    async getMeasureUnits() {
      let x = await MEASUREMENT_UNIT();
      if (
        x?.data?.result
      ) {
        if(!x.data.result.length) {
          this.emptyData.muData = true
        }
        return x.data.result;
      }
    },
    async getInstitutions() {
      let x = await INSTITUTIONS();
      if (x?.data?.result) {
        return x.data.result;
      }
    },
    async getDepartments() {
      let responseDepartmentData = await DEPARTMENT();
      if (
        responseDepartmentData?.data?.result
      ) {
        responseDepartmentData = responseDepartmentData.data.result;
        this.setDepartmentData(responseDepartmentData);
        return responseDepartmentData;
      }
    },
    async getBudgetSourcesData() {
      let responseBudgetData = await BUDGET();
      if (
        responseBudgetData?.data?.result
      ) {
        responseBudgetData = responseBudgetData.data.result;
        return responseBudgetData;
      }
    },
    async getProcedureTypeData() {
      let responseProcedureTypeData = await PROCEDURE_TYPE();
      if (
        responseProcedureTypeData?.data?.result
      ) {
        responseProcedureTypeData = responseProcedureTypeData.data.result;
        return responseProcedureTypeData;
      }
    },
    Modal1Init() {
      const institutionId = this.getUserInstitution(true)?.id
      const q = this.getWindow();
      const r = window.requestAnimationFrame
      const getNeed = () => {
        if (q.needId) {
          r(() => {
            r(() => {
              r(() => {
                this.getNeedData(q.needId);
              });
            });
          });
        }
        this.getNeedUsers();
      }

      

      if(!Number.isInteger(institutionId)) {
        console.error('Nu s-a găsit id-ul institușiei a rolului curent.')
      } else {
        const checkInstErr = () => {
          getNeed()
          this.setLoad()
        }

        this.setSafeLoad()
        INSTITUTION(institutionId).then(res => {
          if(typeof res?.data?.result?.avizoMode == 'boolean') {
            this.Modal1.canAvizo = res.data.result.avizoMode
            this.Modal1.approveMode = res?.data?.result?.approveMode
            getNeed()
            this.setLoad()
          } else {
            checkInstErr()
          }
        }).catch(checkInstErr)
      }

      this.watchers.push([
        this.$watch("Modal1.Input1", (x) => {
          this.Data.needDesc = [];
          if (x) {
            if(x.status == 'completed') {
              this.Modal1.isDisabled = false

              NEEDS_PAGINATED(1, 250, false, `product.id=${x.id}`)
                .then((data) => {
                  if (
                    Array.isArray(data?.data?.result) &&
                    data.data.result.length
                  ) {
                    this.Data.needDesc = data.data.result;
                  } else {
                    this.Data.needDesc = [false];
                  }
                })
                .catch((err) => {
                  this.Data.needDesc = [false];
                  console.error(err);
                });

     /*          this.Modal1.achizitionFromProduct = x.achizitionCategories || []
              this.Modal1.achizitionCategory = x.achizitionCategories.length === 1 ? x.achizitionCategories[0] : null */
            } else {
              this.Modal1.isDisabled = true
              this.$toastr.Add({
                msg: "Poziția de catalog selectată încă nu este validată.",
                type: "warning",
                preventDuplicates: true
              });
            }
          } else {
            this.Modal1.isDisabled = false
/*             this.Modal1.achizitionFromProduct = []
            this.Modal1.achizitionCategory = null */
            this.Modal1.invalids.i15 = false;
          }

          this.Modal1.invalids.i1 = false;
        }),
        1,
      ]);
  /*     this.watchers.push([
        this.$watch("Modal1.achizitionCategory", () => {
          this.Modal1.invalids.i15 = false;
        }),
        1,
      ]); */
      this.watchers.push([
        this.$watch("Modal1.Input2", () => {
          this.Modal1.invalids.i2 = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.Input3", () => {
          this.Modal1.invalids.i3 = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.cotaTva", () => {
          this.Modal1.invalids.cotaTva = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.Input5", () => {
          this.Modal1.invalids.i5 = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.files", () => {
          this.Modal1.invalids.files = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.Input7", () => {
          this.Modal1.invalids.i7 = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.Input8", () => {
          this.Modal1.invalids.i8 = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.selectedAvizo", (x) => {
          this.Modal1.invalids.i9 = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.Input10", (x) => {
          this.Modal1.invalids.i10 = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.isSocial", () => {
          this.Modal1.invalids.isSocial = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.Input11", () => {
          this.Modal1.invalids.i11 = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.Input12", (x) => {
          this.Modal1.selectedAvizo.splice(0);
          this.Modal1.invalids.i12 = false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.revisionState", (val) => {
          this.Modal1.Input11 = null;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.approveState", (val) => {
        if (!val) {
          this.Modal1.Input12 = null;
        }
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.theCharacteristics", (val) => {
          this.Modal1.invalids.i13 = false;
          this.Modal1.showPapp = val?.id === 1 ? true : false;
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.showPapp", (val) => {
          this.Modal1.invalids.i14 = false;
          if (!val) {
            this.Modal1.paap.fill(false);
          }
        }),
        1,
      ]);
      this.watchers.push([
        this.$watch("Modal1.paap", () => {
          this.Modal1.invalids.i14 = false;
        }),
        1,
      ]);
    },
    async Modal5Init(onlyCheck) {
      const data = this.getWindow();
      if(data.readonly) {
        this.Modal5.achizitionCategories = data.productData.achizitionCategories || []
        return
      }
      const setCpv = (val) => (this.Modal5.productStatus = val);

      if (Number.isInteger(data?.productId)) {
        const error = (msg) => {
          if (msg) this.$toastr.e(msg);
          setCpv(false);
        };

        setCpv(0);
        PRODUCTS_CAN_EDIT_CPV(data.productId)
          .then((res) => {
            if (
              this.isNumber(res?.data?.result) &&
              res.data.result > 0 &&
              res.data.result < 4
            ) {
              if(res.data.result === 1) {
                this.$toastr.w({
                  msg: "Poziția de catalog nu poate fi modificată deoarece pe baza acesteia a fost create propuneri de nevoi care deja fac parte dintr-un referat de necesitate.",
                  timeout: 12000,
                });
                this.closeWindow();
                return
              }
              setCpv(res.data.result ?? null);
            } else
              error(
                `În urma verificării produsului răspunsul nu a fost primit.`
              );
          })
          .catch(error);
      } else setCpv(true);

      if(onlyCheck) return

      this.emptyData.cpvCategory = true;
      this.watchers.push([
        this.$watch("Modal5.Input1", (x) => {
          this.Modal5.Input1 = this.uppercaseFirst(x);
          this.Modal5.invalids.i1 = false;
        }),
        5,
      ]);
      this.watchers.push([
        this.$watch("Modal5.Input2", (x) => {
          this.Modal5.invalids.i2 = false;

          if (x && typeof x === "object") {
            this.Modal5.Input4 = `<b>Denumire</b>: ${x.cpvCode} - ${x.name}\n<b>Diviziunea</b>: ${x.division}\n<b>Grupul</b>: ${x.group}\n<b>Clasa</b>: ${x.class}\n<b>Categoria</b>: ${x.category}`;
          } else {
            this.Modal5.Input4 = "";
          }
        }),
        5,
      ]);
      this.watchers.push([
        this.$watch("Modal5.Input3", () => {
          this.Modal5.invalids.i3 = false;
        }),
        5,
      ]);
      this.watchers.push([
        this.$watch("Modal5.Input5", () => {
          this.Modal5.invalids.i5 = false;
        }),
        5,
      ]);
      this.watchers.push([
        this.$watch("Modal5.Input6", async (val) => {
          this.Modal5.Input2 = "";
          this.Modal5.search = "";
          this.Modal5.invalids.i6 = false;
        }),
        5,
      ]);
      this.watchers.push([
        this.$watch("Modal5.Input7", (x) => {
          this.Modal5.invalids.i7 = false;
        }),
        5,
      ]);
    },
    Modal7Init() {
      this.watchers.push([
        this.$watch("Modal7.Input1", () => {
          this.Modal7.invalids.i1 = false;
        }),
        7,
      ]);
      this.watchers.push([
        this.$watch("Modal7.Input2", () => {
          this.Modal7.invalids.i2 = false;
        }),
        7,
      ]);
    },
    Modal8Init() {
      this.watchers.push([
        this.$watch("Modal8.Input1", () => {
          this.Modal8.invalids.i1 = false;
        }),
        8,
      ]);
      this.watchers.push([
        this.$watch("Modal8.Input2", () => {
          this.Modal8.invalids.i2 = false;
        }),
        8,
      ]);
    },
    Modal20Init() {
      const modal = this.getWindow();
      if (modal.modal == 20.1) {
        const x = modal.data;
        if (!x) {
          this.closeWindow();
          this.$toastr.e("Data corruption");
          return;
        }
        this.Modal20 = Object.assign(this.Modal20, {
          Input1: x.first_name,
          Input2: x.last_name,
          Input3: x.cnp,
          Input4: x.email,
          Input5: x.emailForCommunication,
          Input6: x.phone,
          Input7: x.conectxid,
          uuid: x.uuid
        });
      }
    },
    async syncData() {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden'

      const x = this.getWindow();
      const m = x.modal;
      if (m === 1) {
        this.Data.productData = (await this.getProducts(1, this.productsListPerPage * 2)) || [];
      }
      if (m >= 1 && m < 2) {
        if (this.isSuperadmin || this.isAdmin) {
          const data = await this.getDepartments();
          if (data.length) {
            this.Data.departmentData = data;
          } else {
            this.emptyData.departments = true;
          }
        }
        if (this.isSuperadmin) {
          const data = await this.getInstitutions();
          if (data.length) {
            this.Data.institutionData = data;
          } else {
            this.emptyData.institutions = true;
          }
        }
      }
      if (m === 2) {
        this.Data.procedureTypeData = (await this.getProcedureTypeData()) || [];
        if (!this.Data.budgetSourcesData?.length) {
          const budgetSourcesData = await this.getBudgetSourcesData();
          if (budgetSourcesData?.length) {
            this.Data.budgetSourcesData = budgetSourcesData;
          } else {
            this.emptyData.budgetData = true;
          }
        }
        if (this.isSuperadmin || this.isAdmin) {
          const data = await this.getDepartments();
          if (data.length) {
            this.Data.departmentData = data;
          } else {
            this.emptyData.departments = true;
          }
        }
        if (this.isSuperadmin) {
          const data = await this.getInstitutions();
          if (data.length) {
            this.Data.institutionData = data;
          } else {
            this.emptyData.institutions = true;
          }
        }
      }
      if (m === 5 || m === 5.1) {
        if (CatalogSelectModule) {
          CatalogSelectModule = import("./CatalogSelect");
        }

        if(!x.defaultData) {
          if (this.isSuperadmin || this.isAdmin) {
            const data = await this.getDepartments();
            if (data.length) {
              this.Data.departmentData = data;
            } else {
              this.emptyData.departments = true;
            }
          }
          if (this.isSuperadmin) {
            const data = await this.getInstitutions();
            if (data.length) {
              this.Data.institutionData = data;
            } else {
              this.emptyData.institutions = true;
            }
          }
          this.Data.muData = await this.getMeasureUnits();
          
          if(m === 5 && this.PERMISIONS.confirmProducts) {
            this.Data.achizitii = await this.getAchizitii();
          }
        } else {
          this.emptyData.departments = true;
          this.emptyData.institutions = true;
          this.emptyData.muData = true;
        }
        // BUGETARE
      }
      if (m == 14) {
      }
      if (m == 14.1) {
        if (!needHistoryViewerModule) {
          needHistoryViewerModule = import("./needHistoryViewer");
        }
        const popup = this.getWindow();
        if (popup.needId != undefined) {
          NEEDS_VERSIONS(popup.needId)
            .then((data) => {
              if (
                data &&
                data.meta &&
                String(data.meta.HttpStatusCode)[0] == "2" &&
                data.data &&
                Array.isArray(data.data.result)
              ) {
                if (!data.data.result.length) {
                  this.emptyData.VERSIONS = true;
                  return;
                }
                const prepare = [];
                data.data.result.forEach((e, i) => {
                  prepare.push({
                    data: e,
                    id: e.versionNumber,
                    isCurrent: i === data.data.result.length - 1,
                    title: `V${parseFloat(i + 1).toFixed(2)} ${
                      i === data.data.result.length - 1 ? " — Curentă - " + new Date(e.createdAt).toLocaleString("ro-ro", { day:"numeric", month: "numeric", year: "numeric" }) : " - " + new Date(e.createdAt).toLocaleString("ro-ro", { day:"numeric", month: "numeric", year: "numeric" }) 
                    }`,
                  });
                });
                if (popup.canDeleteFiles) {
                  const find = prepare.find((e) => e.isCurrent);
                  if (find) {
                    find.canDelete = true;
                  }
                }
                this.Data.VERSIONS = prepare;
                if (popup.openLatestVersion) {
                  this.oneModelInput = this.Data.VERSIONS.slice(-1)[0];
                  this.Modal14Save();
                }
                return;
              }
              this.$toastr.e("Încercați mai târziu");
              this.closeWindow();
            })
            .catch((err) => {
              this.$toastr.e(err);
              this.closeWindow();
            });
        } else {
          this.$toastr.e("Încercați mai târziu");
          this.closeWindow();
        }
      }
      if (m === 24) {
        this.Modal9.Input1 = +x?.data?.maxValueInLei ?? '';
        this.Modal9.Input2 = +x?.data?.minValueInLei ?? '';
        this.Modal9.Input3 = this.Modal9.achizitionCategory.find(e => e === x?.data?.achizitionCategory);
        this.Modal9.Input4 = +x.data?.maxValueInEU ?? '';
        this.Modal9.Input5 = +x.data?.minValueInEU ?? '';
        this.Modal9.Input6 = x.data?.procedureType ?? '';
        this.Modal9.Input7 = this.Modal9.institutionType.find(e => e === x.data?.institutionType);
        this.Modal24Init();
      }
    },
    Modal24Init() {
      this.watchers.push([
        this.$watch("Modal9.Input1", () => {
          this.Modal9.invalids.i1 = false;
        }),
        24,
      ]);
      this.watchers.push([
        this.$watch("Modal9.Input2", () => {
          this.Modal9.invalids.i2 = false;
        }),
        24,
      ]);
      this.watchers.push([
        this.$watch("Modal9.Input3", () => {
          this.Modal9.invalids.i3 = false;
        }),
        24,
      ]);
      this.watchers.push([
        this.$watch("Modal9.Input4", () => {
          this.Modal9.invalids.i4 = false;
        }),
        24,
      ]);
      this.watchers.push([
        this.$watch("Modal9.Input5", () => {
          this.Modal9.invalids.i5 = false;
        }),
        24,
      ]);
      this.watchers.push([
        this.$watch("Modal9.Input6", () => {
          this.Modal9.invalids.i6 = false;
        }),
        24,
      ]);
      this.watchers.push([
        this.$watch("Modal9.Input7", () => {
          this.Modal9.invalids.i7 = false;
        }),
        24,
      ]);
    },
    Modal24Save() {
      this.setSafeLoad(12000);

      const x = this.getWindow();
      const y = this.Modal9;

      let err = false;
      if (y.Input1 === '') {
        y.invalids.i1 = err = true;
      }
      if (y.Input2 === '') {
        y.invalids.i2 = err = true;
      }
      if (!y.Input3) {
        y.invalids.i3 = err = true;
      }
      if (y.Input4 === '') {
        y.invalids.i4 = err = true;
      }
      if (y.Input5 === '') {
        y.invalids.i5 = err = true;
      }
      if (!y.Input6) {
        y.invalids.i6 = err = true;
      }
    if (!y.Input7) {
        y.invalids.i7 = err = true;
      } 

      if (err) {
        this.setLoad();
      } else {
        const sendData = {};
        const nrComparator = (a,b) => {
          a = +a

          return isNaN(a) || a !== +b
        }
        if (nrComparator(x?.data?.maxValueInLei, y.Input1)) {
          sendData.maxValueInLei = y.Input1;
        }
        if (nrComparator(x?.data?.minValueInLei, y.Input2)) {
          sendData.minValueInLei = y.Input2;
        }
        if(x?.data?.achizitionCategory !== y.Input3) {
          sendData.achizitionCategory = y.Input3;
        }
        if (nrComparator(x?.data?.maxValueInEU, y.Input4)) {
          sendData.maxValueInEU = y.Input4;
        }
        if (nrComparator(x?.data?.minValueInEU, y.Input5)) {
          sendData.minValueInEU = y.Input5;
        }
        if(x?.data?.achizitionModality?.id !== y.Input6.id) {
          sendData.procedureType = y.Input6.id;
        }
        if(x.data?.institutionType !== y.Input7) {
          sendData.institutionType = y.Input7;
        }
        const err = (msg) => {
          this.$toastr.e(msg || "Încercați mai târziu");
          this.setLoad();
        };

        if (Object.keys(sendData).length && x?.data) {
          CURRENCY().then(response => {
            const currency = response?.data?.result[0]
            const EURO = currency.find(el => el.currencyCode == 'EUR')
            sendData.exchangeRate = EURO.rate
            REGISTRU_MODIFY(x.data.id, sendData)
              .then((res) => {
                if (res?.meta?.HttpStatusCode === 200) {
                  this.$toastr.s("Registrul a fost modificat.");
                  this.$emit("Register");
                  this.closeWindow();
                } else {
                  err();
                }
              }).catch((error) => err(error));
          })
            .catch((error) => err(error));
        } else if (Object.keys(sendData).length) {

          CURRENCY().then(response => {
            const currency = response?.data?.result[0]
            const EURO = currency.find(el => el.currencyCode == 'EUR')
            sendData.exchangeRate = EURO.rate
            REGISTRU_ADD(sendData)
              .then((res) => {
                if (res?.meta?.HttpStatusCode === 200) {
                  this.$toastr.s("Registrul a fost adăugat.");
                  this.$emit("Register");
                  this.closeWindow();
                } else {
                  err();
                }
              })
              .catch((error) => err(error));
          })
            .catch((error) => err(error));

        } else {
          this.$toastr.w("Datele nu au fost modificate.");
          this.setLoad();
        }
      }
    },
    async Modal1Save() {
      const errors = [];
      const data = this.Modal1;

      const popup = this.getWindow();
      const modal = popup.modal;
      const avizor = data.selectedAvizo;
      const approver = data.Input12;

      const getAvizors = () =>
        JSON.stringify(
          avizor.map((e, i) => ({
            userUUID: e.uuid,
            userRole: e.id,
            approvalOrder: i,
            action: "undefined",
            reason: "",
          }))
        );
      if(this.Modal1.isDisabled) {
        return
      }
      this.setSafeLoad(12000);
      if (!data.Input1) {
        this.Modal1.invalids.i1 = true;
        errors.push("Poziția de catalog nu a fost selectată.");
      }
    /*   if (!data.achizitionCategory) {
        this.Modal1.invalids.i15 = true;
        errors.push("Categoria de achiziție nu a fost selectată.");
      } */
      if (!this.isNumber(data.Input3)) {
        this.Modal1.invalids.i3 = true;
        errors.push("Prețul de achiziție nu a fost introdus.");
      }
      if (!this.isNumber(data.cotaTva)) {
        this.Modal1.invalids.cotaTva = true;
        errors.push("Cota TVA nu a fost introdusă.");
      }
      if (!data.Input5) {
        this.Modal1.invalids.i5 = true;
        errors.push("Cantitatea nu a fost introdusă.");
      }
      
      if (
        (!approver?.uuid || !approver?.id) && data.approveState
      ) {
        this.Modal1.invalids.i12 = true;
        errors.push("Aprobatorul nu a fost selectat.");
      }
      if (data.revisionState) {
        if (!avizor.length) {
          this.Modal1.invalids.i9 = true;
          errors.push("Nici un avizor nu a fost selectat.");
        }
        if (!data.Input11) {
          this.Modal1.invalids.i11 = true;
          errors.push("Tipul de flux nu a fost selectat.");
        }
      }
    /*   if (typeof data.isSocial?.isSocial != "boolean") {
        this.Modal1.invalids.isSocial = true;
        errors.push("Caracterul nevoii nu a fost selectat.");
      } */
      if (!String(data.Input10).trim()) {
        this.Modal1.invalids.i10 = true;
        errors.push("Descriere tehnică nu a fost completată.");
      }
      if (!data.files.filter((e) => !e.deleted).length) {
        this.Modal1.invalids.files = true;
        errors.push("Nici un fișier nu este atașat.");
      }

      const selectedPaap = (() => {
        const prepare = [];
        data.paap.forEach((e, i) => {
          if (e) {
            prepare.push(i);
          }
        });
        return prepare;
      })();
      if (!data.theCharacteristics) {
        this.Modal1.invalids.i13 = true;
        errors.push(
          "Nici o modalitatea utilizată pentru descrierea caracteristicilor nu a fost aleasă."
        );
      }
      if (data.showPapp && !selectedPaap.length) {
        this.Modal1.invalids.i14 = true;
        errors.push(
          "Caietul de sarcini nu include nici măcar o categorie de standarte."
        );
      }

      if (modal === 1) {
        if (this.isSuperadmin && !data.Input7) {
          this.Modal1.invalids.i7 = true;
          errors.push("Instituția nu a fost selectată.");
        }
        if (this.isAdmin && !data.Input8) {
          this.Modal1.invalids.i8 = true;
          errors.push("Structura organizatorică nu a fost selectat.");
        }
      }


      if (errors.length) {
        this.setLoad();
      } else {
        const decodeToken = this.$jwt.decode(this.$jwt.getToken());
        const currentRole = window.$getRoleAll();
        if (
          this.isUser &&
          (!(currentRole.departmentId || currentRole.departmentId === 0) ||
            !(currentRole.institutionId || currentRole.institutionId === 0))
        ) {
          this.$toastr.e("Încercați să vă autorizați din nou.");
          this.closeWindow();
          return;
        }
        if (
          this.isAdmin &&
          !this.isSuperadmin &&
          !(currentRole.institutionId || currentRole.institutionId === 0)
        ) {
          this.$toastr.e("Încercați să vă autorizați din nou.");
          this.closeWindow();
          return;
        }

        const newAttachedFiles = data.files.filter(
          (e) => !e.__OLD_FILE__ && !e.deleted
        );
        const existentFiles = data.files.filter((e) => e.__OLD_FILE__);

        if (this.getWindow().modal === 1.1) {
          const data = this.Modal1;
          const needId = this.getWindow().needId;
          const oldData = this.getWindow().data;
          const sendData = new FormData();

          if (!oldData.product) {
            oldData.product = {};
          }
          if (
            String(oldData.product.id).trim() !== String(data.Input1.id).trim()
          ) {
            sendData.append("product", parseInt(data.Input1.id));
          }
          if (
            String(oldData?.achizitionCategory?.id).trim() !== String(data?.achizitionCategory?.id).trim()
          ) {
            sendData.append("achizitionCategory", parseInt(data.achizitionCategory.id));
          }
          if (String(oldData.qty).trim() !== String(data.Input5).trim()) {
            sendData.append("qty", parseInt(data.Input5));
          }

          const approvers = approver ? JSON.stringify([`${approver.uuid}*${approver.id}*${approver.label}`]) : ''
          
          if (!oldData.approvers || (oldData.approvers && oldData.approvers[0] != approvers)) {
            sendData.append("approvers", approvers);
          }
      /*     if (
            String(oldData.needIsSocial).trim() !==
            String(data.isSocial.isSocial).trim()
          ) {
            sendData.append("needIsSocial", data.isSocial.isSocial);
          } */
          if (
            String(oldData.technicalDescription).trim() !==
            String(data.Input10).trim()
          ) {
            sendData.append(
              "technicalDescription",
              String(data.Input10).trim()
            );
          }
          if (
            +oldData.estimatedPriceWoTva !== data.Input3
          ) {
            sendData.append(
              "estimatedPriceWoTva",
              data.Input3
            );
          }
          if (
            +oldData.cotaTva !== data.cotaTva
          ) {
            sendData.append(
              "cotaTva",
              data.cotaTva
            );
          }
          let x1 = false;
          if (
            oldData["modalitateaUtilizataDescriereaCaracteristicilor"] !==
            data.theCharacteristics?.name
          ) {
            x1 = true;
            sendData.append(
              "modalitateaUtilizataDescriereaCaracteristicilor",
              data.theCharacteristics?.name
            );
          }
          if (selectedPaap.length || x1) {
            const preparedPaap = selectedPaap.map(
              (e) =>
                ({
                  0: "SR EN ISO",
                  1: "Evaluări tehnice europene [acolo unde este aplicabil]",
                  2: "Specificaţii tehnice comune [acolo unde este aplicabil]",
                  3: "Standarde internaţionale",
                  4: "Sisteme de referinţă tehnice instituite de organisme de standardizare europene [acolo unde este aplicabil]",
                  5: "Alte norme la nivel naţional care reglementează aspecte tehnice/specificaţii tehnice naţionale",
                  6: "Conform descrierii tehnice menționate",
                }[e])
            );
            if (
              !this.compareUnsortedArraysOfObj(
                oldData["caietulDeSarciniTrimiteri"],
                preparedPaap,
                []
              )
            ) {
              sendData.append("caietulDeSarciniTrimiteri", preparedPaap);
            }
          }

          if (existentFiles.find((e) => e.deleted)) {
            sendData.append(
              "attachedDocuments",
              JSON.stringify(existentFiles.filter((e) => !e.deleted).map((e) => e.keyFilename))
            );
          }
          const showAvizo = popup.showAvizo && data.revisionState
          if (showAvizo) {
            sendData.append("avizoMode", data.Input11?.title);
            sendData.append("requestedAvizo", 'true');
            sendData.append("avizatorii", getAvizors());
          }

          if (newAttachedFiles.length) {
            if (!needId) {
              this.$toastr.e("Fișierile nu au fost încărcate.");
            }
            newAttachedFiles
              .filter((e) => !e.deleted)
              .forEach((file) => {
                sendData.append("files", file.file);
              });
          }

          if (!Array.from(sendData.keys()).length) {
            this.$toastr.w("Datele nu au fost modificate.");
            this.setLoad();
            return;
          }

          if (!showAvizo && data.approveState) {
            sendData.append("status", 'pending');
            sendData.append("requestedAvizo", 'false');
          } else if (!showAvizo) {
            sendData.append("status", 'generated');
          } 

          await NEEDS_MODIFY_FORMDATA(needId, sendData)
            .then((data) => {
              if (
                (data.status && String(data.status) !== "2") ||
                (data.statusCode && String(data.statusCode) !== "2") ||
                (data &&
                  data.meta &&
                  data.meta.HttpStatusCode &&
                  String(data.meta.HttpStatusCode)[0] !== "2")
              ) {

                this.$toastr.e(data.response);
                this.closeWindow();
                return;
              }
              if (this.getWindow().modal === 1.1) {
                this.$toastr.s("Necesitate modificată");
                this.closeWindow();
                this.$emit("Needs", "reload");
                return;
              }
              this.$toastr.s("Necesitate salvată");
              this.closeWindow();
              this.$emit("Needs", "reload");
            })
            .catch((err) => {
              this.$toastr.e(err);
              this.closeWindow();
            });
          return;
        }
        const formData = new FormData();

        if (data.approveState) {
          formData.append("status", 'pending')} else {formData.append("status", 'generated')
        };

        formData.append("product", +data.Input1.id);
        //formData.append("achizitionCategory", +data.achizitionCategory.id);
        formData.append("estimatedPriceWoTva", data.Input3);
        formData.append("cotaTva", data.cotaTva);
        formData.append("qty", +data.Input5);
        formData.append("needIsSocial", data.Input1.cpvCategory.isSocial);
        formData.append("technicalDescription", data.Input10);
        formData.append("createdByUserId", +decodeToken.id);
        formData.append("lastInteractedUserId", +decodeToken.id);
        formData.append(
          "modalitateaUtilizataDescriereaCaracteristicilor",
          data.theCharacteristics.name
        );
        formData.append(
          "institution",
          this.isSuperadmin &&
            data.Input7 &&
            data.Input7.id &&
            data.Input7.id !== 0
            ? parseInt(data.Input7.id)
            : parseInt(currentRole.institutionId)
        );
        formData.append(
          "department",
          this.isAdmin && data.Input8 && data.Input8.id && data.Input8.id !== 0
            ? parseInt(data.Input8.id)
            : parseInt(currentRole.departmentId)
        );

        if (data.approveState) {
          formData.append("approvers", JSON.stringify([`${approver.uuid}*${approver.id}*${approver.label}`]));
        }
  
        if (data.showPapp) {
          formData.append(
            "caietulDeSarciniTrimiteri",
            selectedPaap.map(
              (e) =>
                ({
                  0: "SR EN ISO",
                  1: "Evaluări tehnice europene [acolo unde este aplicabil]",
                  2: "Specificaţii tehnice comune [acolo unde este aplicabil]",
                  3: "Standarde internaţionale",
                  4: "Sisteme de referinţă tehnice instituite de organisme de standardizare europene [acolo unde este aplicabil]",
                  5: "Alte norme la nivel naţional care reglementează aspecte tehnice/specificaţii tehnice naţionale",
                  6: "Conform descrierii tehnice menționate",
                }[e])
            )
          );
        }
        if (data.revisionState) {
          formData.append("avizoMode", data.Input11?.title);
          formData.append("requestedAvizo", true);
          formData.append("avizatorii", getAvizors());
        }

        if (newAttachedFiles.length) {
          newAttachedFiles
            .filter((e) => !e.deleted)
            .forEach((file) => {
              formData.append("files", file.file);
            });
        }

        const loadingTooltip = this.$toastr.Add({
          title: "Se trimite...",
          msg: "Necesitatea este în proces de creare.",
          type: "info",
          timeout: 0,
          progressbar: true,
          progressBarValue: 0,
        });
        setTimeout(() => {
          var i = 0;
          function move() {
            if (i == 0) {
              i = 1;
              var width = 1;
              var id = setInterval(frame, 40);
              function frame() {
                if (width >= 80) {
                  clearInterval(id);
                  i = 0;
                } else {
                  width++;
                  loadingTooltip.progressBarValue = width;
                }
              }
            }
          }
          move();
        }, 1000);
        const del = () => this.$toastr.Close(loadingTooltip);
        const modifiedBase = this.getWindow()?.modal === 1.1;
        this.closeWindow();
        NEEDS_ADD(formData)
          .then((res) => {
            if (
              !res ||
              (res.status && String(res.status) !== "2") ||
              (res.statusCode && String(res.statusCode) !== "2") ||
              (res &&
                res.meta &&
                res.meta.HttpStatusCode &&
                String(res.meta.HttpStatusCode)[0] !== "2")
            ) {
              this.$toastr.e("Încercați mai târziu");
              del();
              return;
            }
            if (modifiedBase) {
              this.$toastr.s("Necesitate modificată");
              this.$emit("Needs", "reload");
              del();
              return;
            }
            if (newAttachedFiles.length) {
              if (!(res.data && res.data.result && res.data.result.id)) {
                this.$toastr.e("Fișierile nu au fost încărcate.");
                del();
              }
              const formData = new FormData();
              newAttachedFiles.forEach((file) => {
                formData.append("files", file);
              });
            }

            window.requestAnimationFrame(() => {
              this.$toastr.s("Necesitate salvată");
              del();
              this.$emit("Needs", "reload");
            });
          })
          .catch((err) => {
            this.$toastr.e(err);
            del();
          });
      }
    },
    Modal5Save(productStatus) {
      const errors = [];
      const data = this.Modal5;
      const modal = this.getWindow().modal;

      if (modal === 5.1) {
        if (!Number.isInteger(productStatus))
          productStatus = data.productStatus;

        if (productStatus === 2) {
          this.$modal.show(
            DialogModal,
            {
              target: "ProductEditConfirm",
              title: "Editare poziție de catalog",
              content:
                '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">După editarea acestui produs, unele propuneri de nevoi create pe baza acestuia vor fi anulate automat.</p></span>',
              closeBtnText: "Anulează",
              button: {
                type: 2,
                value: "Confirmă",
                handler: () => {
                  this.Modal5Save(3);
                },
              },
            },
            {
              name: "ProductEditConfirm",
              adaptive: true,
              width: "650",
              height: "400",
            },
            {
              "before-close": function () {},
            }
          );
          return;
        }
        if (!productStatus || productStatus !== 3) {
          this.$toastr.e(
            "Verificarea produsului a eșuat."
          );
          this.closeWindow();
          return;
        }
      }
      this.setSafeLoad(12000);
      if (this.isSuperadmin && !data.Input5) {
        this.Modal5.invalids.i5 = true;
        errors.push("Instituția produsului nu a fost selectată.");
      }
      if (!data.Input6) {
        this.Modal5.invalids.i6 = true;
        errors.push("Poziția catalogului nu a fost selectată.");
      }
      if (!data.Input1 || !String(data.Input1).trim()) {
        this.Modal5.invalids.i1 = true;
        errors.push("Denumirea produsului a fost introdusă incorect.");
      }
      if (!data.Input3) {
        this.Modal5.invalids.i3 = true;
        errors.push("Măsura de unitate a produsului nu a fost selectată.");
      }
/*       if(modal === 5 && this.PERMISIONS.confirmProducts) {
        if(!data.Input7.length) {
          this.Modal5.invalids.i7 = true;
          errors.push("Selectați minim o categorie de achiziție.");
        }
      } */
      if (!data.Input2) {
        this.Modal5.invalids.i2 = true;
        const msg = "Codul CPV nu a fost selectat."
        errors.push(msg);
        if(errors.length === 1) this.$toastr.w(msg)
      }

      if (!errors.length) {
        if (modal === 5) {
          this.createProduct();
        } else if (modal === 5.1) {
          this.editProduct();
        }
      } else {
        this.setLoad();
      }
    },
    editProduct() {
      const x = this.getWindow()
      const data = this.Modal5;
      const productId = x?.productId || {};
      const oldData = x?.productData || {};
      const sendData = {};

      if (oldData.institution?.id !== data.Input5?.id) {
        sendData.institution = +data.Input5.id;
      }
      if (
        String(oldData.name).trim() !==
        this.uppercaseFirst(String(data.Input1).trim())
      ) {
        sendData.name = this.uppercaseFirst(String(data.Input1).trim());
      }
      if (oldData.cpvCategory?.id !== data.Input2?.id) {
        sendData.cpvCategory = +data.Input2.id;
      }
      if (oldData.measurementUnit?.id !== data.Input3?.id) {
        sendData.measurementUnit = +data.Input3.id;
      }
      if (String(oldData.description).trim() !== String(data.Input4).trim()) {
        sendData.description = String(data.Input4).trim();
      }

      if (Object.keys(sendData).length) {
        PRODUCTS_MODIFY(productId, sendData)
          .then((responseData) => {
            if (
              !(
                responseData &&
                responseData.meta &&
                String(responseData.meta.HttpStatusCode)[0] === "2"
              )
            ) {
              this.$toastr.e("Încercați mai târziu.");
              this.closeWindow();
              return;
            }
            this.$toastr.s("Poziția de catalog a fost modificată.");
            this.$emit("Products", "reload");
            this.closeWindow();
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.closeWindow();
          });
      } else {
        this.$toastr.w("Datele nu au fost modificate.");
        this.setLoad();
      }
    },
    createProduct() {
      const data = this.Modal5;
      const sendData = {
        name: this.uppercaseFirst(String(data.Input1).trim()),
        cpvCategory: parseInt(data.Input2.id),
        measurementUnit: parseInt(data.Input3.id),
        description: String(data.Input4).trim(),
        institution: null,
        status: 'in_progres'
      };

      if (this.isSuperadmin) {
        sendData["institution"] = data.Input5.id;
      } else {
        const role = window.$getRoleAll();
        if (role?.institutionId) {
          sendData["institution"] = parseInt(role?.institutionId);
        } else {
          this.$toastr.e("Încercați să vă autorizați din nou.");
          this.closeWindow();
          this.setLoad()
          return;
        }
      }
      if(this.PERMISIONS.confirmProducts) {
        //if(Number.isInteger(data.Input7?.length)) {
          //sendData.achizitionCategories = data.Input7
          sendData.status = 'completed'
        /* } else {
          this.$toastr.e('Nu s-a putut găsi categoria de achiziție selectată, anunțați administratorul.')
          this.setLoad()
          return
        } */
      }

      PRODUCTS_ADD(sendData)
        .then(async (data) => {
          if (String(data?.meta?.HttpStatusCode)[0] !== "2") {
            if (data && data.message && data.message.includes('product_exit')) {
              this.$toastr.e("Acest produs a fost adaugat deja.");
            } else {
              this.$toastr.e("Încercați mai târziu.");
            }
            this.closeWindow();
            return;
          }

          if (this.PERMISIONS.confirmProducts) {
            this.$toastr.s("Poziția de catalog a fost creată." );
          }
          else {
            this.$toastr.i("Poziția de catalog a fost trimsă spre aprobare." );
          }

          if (this.getWindow()?.fromNeedFlow) {
            this.setProductData([]);
            this.Data.productData = (await this.getProducts()) || [];
            if (Number.isInteger(data.data?.result?.id)) {
              this.Modal1.Input1 = this.Data.productData.find(
                (e) => e.id === data.data.result.id
              );
            }
          } else {
            this.$emit("Products", "reload");
          }
          this.closeWindow();
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
        });
    },
    delProduct(status) {
      const initialStatus = this.Modal5.productStatus

      if (!Number.isInteger(status)) status = initialStatus;

      if (status === 1) {
        this.$toastr.w({
          msg: "Poziția de catalog nu poate fi ștearsă deoarece pe baza acesteia au fost create propuneri de nevoi care deja fac parte dintr-un referat de necesitate.",
          timeout: 12000,
        });
        this.closeWindow();
        return;
      }
      if (status === 2) {
        this.$modal.show(
          DialogModal,
          {
            target: "ProductDeleteConfirm",
            title: "Editare poziție de catalog",
            content:
              '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">După ștergerea acestei poziții de catalog, unele propuneri de nevoi create pe baza acestuia vor fi anulate automat.</p></span>',
            closeBtnText: "Anulează",
            button: {
              type: 2,
              value: "Confirmă",
              handler: () => {
                this.delProduct(3);
              },
            },
          },
          {
            name: "ProductDeleteConfirm",
            adaptive: true,
            width: "650",
            height: "400",
          },
          {
            "before-close": function () {},
          }
        );
        return;
      }
      if (!status || status !== 3) {
        this.$toastr.e(
          "Nu s-a putut verifica dacă produsul poate fi modificat."
        );
        this.closeWindow();
        return;
      }
      this.setSafeLoad(12000);

      const productId = this.getWindow().productId;
    
      if (productId) {
        PRODUCTS_DELETE(productId, {deleteType: 'hard'})
          .then((response) => {
            if (
              !(
                response &&
                response.meta &&
                String(response.meta.HttpStatusCode)[0] === "2"
              )
            ) {
              this.$toastr.e("Încercați mai târziu.");
              this.closeWindow();
              return;
            }
            this.$toastr.s("Poziția de catalog a fost ștearsă");
            this.$emit("Products", "reload");
            this.closeWindow();
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.closeWindow();
          });
      } else {
        this.$toastr.e("Încercați mai târziu");
        this.closeWindow();
      }
    },
    Modal7Save() {
      const errors = [];
      const data = this.Modal7;
      const modal = this.getWindow().modal;

      if (!data.Input1 || !String(data.Input1).trim()) {
        this.Modal7.invalids.i1 = true;
        errors.push("Denumirea sursei buget a fost introdusă incorect.");
      }
      if (!data.Input2 || !String(data.Input2).trim()) {
        this.Modal7.invalids.i2 = true;
        errors.push("Descrierea sursei buget a fost introdusă incorect.");
      }

      this.setSafeLoad(12000);
      if (!errors.length) {
        if (modal === 7) {
          this.createBudget();
        } else if (modal === 7.1) {
          this.editBudget();
        }
      } else {
        this.setLoad();
      }
    },
    createBudget() {
      const data = this.Modal7;
      const sendData = {
        name: String(data.Input1).trim(),
        description: String(data.Input2).trim(),
      };

      BUDGET_ADD(sendData)
        .then((data) => {
          if (
            !(data && data.meta && String(data.meta.HttpStatusCode)[0] === "2")
          ) {
            this.$toastr.e("Încercați mai târziu.");
            this.closeWindow();
            return;
          }
          this.$toastr.s("Sursa buget a fost creată.");
          this.$emit("Budget", "reload");
          this.closeWindow();
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
        });
    },
    editBudget() {
      const data = this.Modal7;
      const budgetId = this.getWindow().budgetId;
      const oldData = this.getWindow().budgetData;
      const sendData = {};

      if (String(oldData.name).trim() !== String(data.Input1).trim()) {
        sendData.name = String(data.Input1).trim();
      }
      if (String(oldData.description).trim() !== String(data.Input2).trim()) {
        sendData.description = String(data.Input2).trim();
      }

      if (Object.keys(sendData).length) {
        BUDGET_MODIFY(budgetId, sendData)
          .then((responseData) => {
            if (
              !(
                responseData &&
                responseData.meta &&
                String(responseData.meta.HttpStatusCode)[0] === "2"
              )
            ) {
              this.$toastr.e("Încercați mai târziu.");
              this.closeWindow();
              return;
            }
            this.$toastr.s("Sursa buget a fost modificată.");
            this.$emit("Budget", "reload");
            this.closeWindow();
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.closeWindow();
          });
      } else {
        this.$toastr.w("Datele nu au fost modificate.");
        this.setLoad();
      }
    },
    delBudget() {
      this.setSafeLoad(12000);
      const budgetId = this.getWindow().budgetId;
      if (budgetId) {
        BUDGET_DELETE(budgetId)
          .then((response) => {
            if (
              !(
                response &&
                response.meta &&
                String(response.meta.HttpStatusCode)[0] === "2"
              )
            ) {
              this.$toastr.e("Încercați mai târziu");
              this.closeWindow();
              return;
            }
            this.$toastr.w("Sursa buget a fost ștearsă");
            this.$emit("Budget", "reload");
            this.closeWindow();
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.closeWindow();
          });
      } else {
        this.$toastr.e("Încercați mai târziu");
        this.closeWindow();
      }
    },
    Modal8Save() {
      const errors = [];
      const data = this.Modal8;
      const modal = this.getWindow().modal;

      this.setSafeLoad(12000);
      if (!data.Input1 || !String(data.Input1).trim()) {
        this.Modal8.invalids.i1 = true;
        errors.push("Denumirea categoriei cpv a fost introdusă incorect");
      }
      if (
        !data.Input2 ||
        String(data.Input2).trim().length != 10 ||
        String(data.Input2).trim()[8] != "-" ||
        !isNumeric(String(data.Input2).split("-")[0]) ||
        !isNumeric(String(data.Input2).split("-")[1])
      ) {
        this.Modal8.invalids.i2 = true;
        errors.push("Descrierea categoriei cpv a fost introdusă incorect");
        if (data.Input1 || String(data.Input1).trim()) {
          this.$toastr.w("Codul cpv a fost introdus incorect!");
        }
      }

      if (!errors.length) {
        if (modal === 8) {
          this.createCpvCategory();
        } else if (modal === 8.1) {
          this.editCpvCategory();
        }
      } else {
        this.setLoad();
      }
    },
    createCpvCategory() {
      const data = this.Modal8;
      const sendData = {
        name: String(data.Input1).trim(),
        cpvCode: String(data.Input2).trim(),
      };

      CPV_CATEGORY_ADD(sendData)
        .then((data) => {
          if (data.message && data.message.toLowerCase().search("duplicate")) {
            this.$toastr.e("Cod CPV existent.");
            this.setLoad();
            return;
          }
          if (
            !(data && data.meta && String(data.meta.HttpStatusCode)[0] === "2")
          ) {
            this.$toastr.e("Încercați mai târziu.");
            this.closeWindow();
            return;
          }
          this.$toastr.s("Categoria cpv a fost creată.");
          this.$emit("Cpv", "reload");
          this.closeWindow();
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
        });
    },
    editCpvCategory() {
      const data = this.Modal8;
      const cpvCategoryId = this.getWindow().cpvCategoryId;
      const oldData = this.getWindow().cpvCategoryData;
      const sendData = {};

      if (String(oldData.name).trim() !== String(data.Input1).trim()) {
        sendData.name = String(data.Input1).trim();
      }
      if (String(oldData.cpvCode).trim() !== String(data.Input2).trim()) {
        sendData.cpvCode = String(data.Input2).trim();
      }

      if (Object.keys(sendData).length) {
        CPV_CATEGORY_MODIFY(cpvCategoryId, sendData)
          .then((responseData) => {
            if (
              responseData.message &&
              responseData.message.toLowerCase().search("duplicate")
            ) {
              this.$toastr.e("Cod CPV existent.");
              return;
            }
            if (
              !(
                responseData &&
                responseData.meta &&
                String(responseData.meta.HttpStatusCode)[0] === "2"
              )
            ) {
              this.$toastr.e("Încercați mai târziu.");
              this.closeWindow();
              return;
            }
            this.$toastr.s("Categoria cpv a fost modificată.");
            this.$emit("Cpv", "reload");
            this.closeWindow();
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.closeWindow();
          });
      } else {
        this.$toastr.w("Datele nu au fost modificate.");
        this.setLoad();
      }
    },
    delCpvCategory() {
      this.setSafeLoad(12000);
      const cpvCategoryId = this.getWindow().cpvCategoryId;
      if (cpvCategoryId) {
        CPV_CATEGORY_DELETE(cpvCategoryId)
          .then((response) => {
            if (
              !(
                response &&
                response.meta &&
                String(response.meta.HttpStatusCode)[0] === "2"
              )
            ) {
              this.$toastr.e("Încercați mai târziu");
              this.closeWindow();
              return;
            }
            this.$toastr.w("Categoria cpv a fost ștearsă.");
            this.$emit("Cpv", "reload");
            this.closeWindow();
          })
          .catch((err) => {
            this.$toastr.e(err);
            this.closeWindow();
          });
      } else {
        this.$toastr.e("Încercați mai târziu");
        this.closeWindow();
      }
    },
    Modal11Save() {
      const x = this.getWindow();
      this.setSafeLoad(12000);

      const currentTime = window.$getDateAndTime();
      const commentInput = this.oneModelInput;

      if (x.rejectData) {
        const data = x.rejectData;
        const prepareData = {
          ...data,
          toStatus: data.toStatus || null,
          rejectedBy: data.rejectedByUser || null,
          rejectedTime: currentTime || null,
          approving: data.approving,
          rejecting: data.rejecting,
        };

        if (commentInput && String(commentInput).trim()) {
          prepareData.rejectedComment = commentInput;
        }
        
        if(typeof x.afterConfirm == 'function') {
          x.afterConfirm(prepareData)
        }
        if (Number.isInteger(data.needIndex) || data.cancelAvizo) {
          prepareData.needIndex = data.needIndex;
          this.$emit("NeedReject", prepareData);
        } else if (data.referenceData) {
          this.$emit("ReferenceReject", {
            ...prepareData,
            ...data.referenceData,
          });
        }
      } else if (x.userIndex) {
        this.$emit("userStatus", { ...x, reason: commentInput });
      }
      this.closeWindow();
    },
    Modal12Save() {
      this.setSafeLoad(12000);
      const proposals = this.oneModelInput;
      if (!proposals && !(Array.isArray(proposals) && proposals.length)) {
        this.$toastr.w("Nici o propuenre nu a fost selectată.");
        this.setLoad();
        return;
      }
      setTimeout(() => {
        this.$emit("AddProposalsToReference", proposals);
        this.closeWindow();
      }, 250);
    },
    async Modal14Save() {
      const input = this.oneModelInput;
      const popup = this.getWindow();
      if (!input) {
        this.$toastr.w("Nici o versiune nu a fost selectată.");
        this.setLoad();
        return;
      }
      if (popup.referenceId != undefined) {
        this.$emit("ViewReferenceVersion", {
          version: input.id,
          referenceId: popup.referenceId,
        });
        this.closeWindow();
      } else if (popup.needId != undefined) {
        const data = this.Data.VERSIONS.find((e) => e.id === input.id)?.data;
        const version = data?.data || null;


        if (typeof version.createdByUser == "string") {
          await USERS_ONCE(version.createdByUser, true)
            .then((res) => {
              res = res?.data?.result || null

              version.createdBy = {
                last_name: res?.last_name || '-',
                first_name: res?.first_name || '-',
                idConectX: res?.id || '-',
              };
            })
            .catch((err) => {
              this.$toastr.e(
                "Detectarea solicitantului versiunii a eșuat."
              );
              version.createdBy = {
                last_name: "-",
                first_name: "-",
                idConectX: "-",
              };
            });
        }
        if (typeof data.createdBy == "string") {
          const parseName = data.createdBy.split(" ");
          version.modifiedBy = {
            last_name: parseName[1],
            first_name: parseName[0],
            idConectX: data.createdBy.split("=")[1],
          };
        }
        let approver =
          Array.isArray(version.approvers) && version.approvers[0]
            ? version.approvers[0]
            : false;
        approver = this.JSONvalide(approver)
          ? JSON.parse(approver)[0]?.split("*")
          : this.isString(approver, true)?.split("*") || [];
        this.addWindow({
          title: `Versiunea ${String(input.title).split(" ")[0]}`,
          isCurrent: input.isCurrent,
          // subtitle: `Nevoie: ${productName.split(':')[1]}`,
          data: {
            all: this.Data.VERSIONS,
            needData: {
              ...version,
              showModified: input.id > 0,
              approver: approver[2] ? approver[2] : false,
            },
            additionalData: data,
            status: popup.needStatus,
            canDelete: false,
          },
          modal: 11.1,
        });
      }
    },
    requestPasswordReset() {
      if (this.Modal20.requestPasswordReset) return;
      this.Modal20.requestPasswordReset = true;
      const x = this.getWindow();
      if (Math.floor(x.modal) !== 20 || !x.data?.email) {
        this.$toastr.e("Data corruption");
        return;
      }
      if (checkPassSession(x.data.email)) {
        this.$toastr.w(
          "Deja ați trimis o cerere către e-mail-ul dat. Încercați mai târziu."
        );
        this.Modal20.requestPasswordReset = false;
        return;
      }
      REQUEST_PASSWORD_RESET(x.data.email)
        .then((res) => {
          if (res?.meta?.HttpStatusCode === 200) {
            this.$toastr.s(
              "Cererea de resetare parolă a fost trimisă pe e-mail."
            );
            savePassSession(x.data.email);
          } else {
            this.$toastr.e("Încercați mai târziu");
          }
          this.Modal20.requestPasswordReset = false;
        })
        .catch((err) => {
          this.Modal20.requestPasswordReset = false;
          this.$toastr.e(err);
        });
    },
    Modal20Save() {
      const errors = [];
      const data = this.Modal20;
      const modal = this.getWindow();

      this.setSafeLoad(12000);
      const a = (a) => String(a).trim();
      if (a(data.Input1).length < 3) {
        errors.push("Numele trebuie să conțină minim 3 caractere.");
      }
      if (a(data.Input2).length < 3) {
        errors.push("Prenumele trebuie să conțină minim 3 caractere.");
      }
      
      if (data.Input3 && a(data.Input3) != "" && new RegExp("[^0-9]", "g").test(a(data.Input3))) {
        errors.push("CNP-ul trebuie să conțină doar cifre.");
      }

      if (data.Input3 && a(data.Input3) != "" && data.cnpIsOk !== 1) {
        errors.push("CNP-ul nu este valid.");
      }
      if (data.Input3 && a(data.Input3) != "" && a(data.Input3).length !== 13) {
        errors.push("CNP-ul trebuie să fie compus din 13 caractere.");
      }
      if (a(data.Input4).length < 3) {
        errors.push("Username trebuie să conțină minim 3 caractere.");
      }
      if (!this.validateEmail(a(data.Input5))) {
        errors.push("E-mail-ul de corespondență este introdus greșit.");
      }
      if (!data.phoneStatus?.valid) {
        errors.push("Telefonul este introdus greșit.");
      }
      if (data.usernameIsOk === 2) {
        errors.push("Username deja există!");
      }
      if (data.emailIsUnique === 2) {
        errors.push("Email-ul deja există!");
      }

      const existingRoles = data.roles

      if (!errors.length) {
        if(!existingRoles.length) {
          this.setLoad();
          this.$toastr.w("Este nevoie să atribuiți măcar un rol.");
          return
        }

        if (modal?.modal === 20.1) {
          const sendData = {};
          if (!modal.data) {
            this.$toastr.e("Data corrupt #094871");
            return;
          }
          const z = modal.data;
          const y = { ...data };
          const createRoles = [];
          const changeRoles = [];
          const promises = [];

          y.Input1 = a(data.Input1);
          y.Input2 = a(data.Input2);
          y.Input3 = data.Input3 && a(data.Input3) != "" ? a(data.Input3) : null;
          y.Input4 = a(data.Input4);
          y.Input5 = a(data.Input5);
          y.Input6 = a(data.Input6);

          if (y.Input1 !== a(z.first_name)) {
            sendData["first_name"] = y.Input1;
          }
          if (y.Input2 !== a(z.last_name)) {
            sendData["last_name"] = y.Input2;
          }
          if (y.Input3 !== z.cnp) {
            sendData["cnp"] = y.Input3;
          }
          if (y.Input4 !== a(z.email)) {
            sendData["email"] = y.Input4;
          }
          if (y.Input5 !== a(z.emailForCommunication)) {
            sendData["emailForCommunication"] = y.Input5;
          }
          if (y.Input6 !== a(z.phone)) {
            sendData["phone"] = y.Input6;
          }
          if (y.Input7 != a(z.conectxid)) {
            sendData["conectxid"] = y.Input7;
          }
          sendData
          if (Array.isArray(z.roles)) {
            for(let e of this.removeArrDuplicatesWithkeys([...z.roles, ...y.roles])) {
              if(e._newCreated) {
                const x = e.isConsilier
                delete e.isConsilier
                
                createRoles.push({
                  ...e,
                  isConsilier: x?.model === true
                });
              } else {
                if(e._isNewStatus) {
                  changeRoles.push({
                    id: e.id,
                    type: '_isNewStatus',
                    data: e
                  });
                }
                if(e.isConsilier.modified) {
                  changeRoles.push({
                    id: e.id,
                    type: 'isConsilier',
                    data: {
                      isConsilier: e.isConsilier.model
                    }
                  });
                }
              }
            }
          }
          if (
            !Object.keys(sendData).length &&
            !(createRoles.length + changeRoles.length)
          ) {
            this.$toastr.w("Datele nu au fost modificate.");
            this.setLoad();
            return;
          }

          (async () => {
            let userModified = false;
            if (Object.keys(sendData).length) {
              sendData.actionReason = ' '
              await USER_MODIFY(z.uuid, sendData)
                .then((res) => {
                  const status = res?.data?.result?.statusCode;
                  if (
                    !Number.isInteger(status) ||
                    status < 200 ||
                    status > 299
                  ) {
                    this.$toastr.e(`Server status: ${status}`);
                    this.closeWindow();
                    return;
                  }
                  this.$toastr.s(
                    "Modificarea utilizatorului a decurs cu succes"
                  );
                  userModified = true;
                  if (!createRoles.length) {
                    this.closeWindow();
                    this.$emit("userList");
                  }
                })
                .catch((err) => {
                  this.$toastr.e(err);
                  this.closeWindow();
                });
            }

            createRoles.forEach((role) =>
              promises.push(
                USERS_ADD_ROLE({
                  user: z.uuid,
                  role: role?.role,
                  isConsilier: role?.isConsilier,
                  institution: role?.institution?.id || null,
                  department: role?.department?.id || null,
                  reason: role?._newCreated.comment || ''
                })
              )
            );
            changeRoles.forEach(
              (role) => promises.push(
                role?.type == '_isNewStatus' ? ACTIVATE_ROLE(role?.id, {
                  reason: role?.data._isNewStatus.comment
                }) : EDIT_ROLE(role?.id, {
                  isConsilier: role?.data.isConsilier
                })
              )
            );
            if (createRoles.length || changeRoles.length) {
              Promise.all(promises)
                .then((res) => {
                  if (
                    res?.find(
                      (e) =>
                        !(
                          e?.meta?.HttpStatusCode &&
                          String(parseInt(e.meta.HttpStatusCode))[0] == "2"
                        )
                    )
                  ) {
                    this.$toastr.e(
                      "Setarea rolurilor pentru utilizatorul selectat a decurs cu errori."
                    );
                  } else if (!userModified) {
                    this.$toastr.s(
                      "Rolurile utilizatorului selectat au fost modificate."
                    );
                  }

                  this.closeWindow();
                  this.$emit("userList");
                })
                .catch((err) => this.$toastr.e(err));
            }
          })();
        } else {
          const sendData = {
            cnp: data.Input3,
            first_name: data.Input1,
            last_name: data.Input2,
            email: data.Input4,
            emailForCommunication: data.Input5,
            phone: data.phoneStatus.formatted || data.Input6,
            password: `Aa1!${this.makeid(12)}`,
            conectxid: data.conectxid
          };

          const err = (msg) => {
            this.$toastr.e(msg || "A apărut o eroare.");
            this.closeWindow();
            this.$emit("userList");
          };
          USERS_ADD(sendData)
            .then((res) => {
              if (res?.data?.newUser) {
                const promises = [];
                data.roles?.forEach((e) => {
                  promises.push(
                    USERS_ADD_ROLE({
                      user: res.data.newUser,
                      role: e?.role,
                      isConsilier: false,
                      institution: e.institution?.id || null,
                      department: e.department?.id || null,
                    })
                  );
                });
                Promise.all(promises).then((res) => {
                  if (
                    res?.find(
                      (e) =>
                        !(
                          e?.meta?.HttpStatusCode &&
                          String(parseInt(e.meta.HttpStatusCode))[0] == "2"
                        )
                    )
                  ) {
                    this.$toastr.e(
                      "Atașarea rolurilor de utilizatorul nou creat a decurs cu errori."
                    );
                  } else {
                    this.$toastr.s(
                      "Utilizatorul a fost creat cu succes"
                    );
                  }
                  this.closeWindow();
                  this.$emit("userList");
                });
              } else {
                err();
              }
            })
            .catch(err);
        }
      } else {
        this.$toastr.w(errors[0]);
        this.setLoad();
        this.Modal20.checkerCount++
      }
    },
    deleteUser() {
      this.setSafeLoad(12000);

      const modal = this.getWindow();
      if (!modal.data.uuid) {
        this.$toastr.e(
          "Identificatorul utilizatorului nu a fost găsit"
        );
        return;
      }
      USERS_DELETE(modal.data.uuid).then((res) => {
        if (!res) {
          this.$toastr.e(
            "Ștergerea utilizatorului a decurs cu errori."
          );
          this.closeWindow();
          this.$emit("userList");
          return;
        }
        if (!this.checkHttpStatusCode(res.meta?.HttpStatusCode)) {
          this.$toastr.e(`Server status: ${res.raw?.serverStatus}`);
          this.closeWindow();
          this.$emit("userList");
          return;
        }
        if (this.getUuid() === modal.data.uuid) {
          this.logout();
        }
        this.$toastr.s("Ștergea utilizatorului a decurs cu succes");
        this.closeWindow();
        this.$emit("userList");
      });
    },
    deleteNeed() {
      this.setSafeLoad(12000);
      const q = this.getWindow();
      const id = q?.data?.id;
      if (!Number.isInteger(id)) {
        this.$toastr.e("Data corruption");
        return;
      }
      NEEDS_DELETE(id)
        .then((data) => {
          if (
            !(data && data.meta && String(data.meta.HttpStatusCode)[0] === "2")
          ) {
            this.$toastr.e("Încercați mai târziu");
            this.closeWindow();
            return;
          }
          this.$toastr.s("Nevoia a fost ștearsă.");
          this.closeWindow();
          this.$emit("Needs", "reload");
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
        });
    },
    deleteDepartmentOrInstitution(type, id) {
      this.setSafeLoad(12000);
      if (type === 1) {
        this.$emit("Delete_Institution", { id: id });
      }
      if (type === 2) {
        this.$emit("Delete_Department", { id: id });
      }
      this.closeWindow();
    },
    async getNeedData(id) {
      if (!Number.isInteger(id)) return;
      const q = this.getWindow();
      if (q.modal === 1.2) {
        return;
      }
      let avizoMode = null;
      let avizors = [];
      this.setSafeLoad(12000);
      if (q.showAvizo) {
        await Promise.all([this.getNeedUsers(), NEEDS_VERSIONS(q.needId)]).then(
          (vals) => {
            const res = vals[1];
            if (Array.isArray(res?.data?.result)) {
              const x = res.data.result;
              const findLastAvizo = x.reverse().find((e) => e.data?.avizo)
                ?.data?.avizo;
              if (findLastAvizo) {
                this.Modal1.revisionState = true;
                avizoMode =
                  this.Modal1.revisionStates.find(
                    (e) => e.title == findLastAvizo.avizoMode
                  ) || null;
                avizors =
                  findLastAvizo.avizatorii?.map((e) => ({
                    ...e.avizatorDetails,
                    label: `${e.avizatorDetails.last_name} ${e.avizatorDetails.first_name} / ${e.avizatorDetails.id} — ${e.avizatorDetails.department} — ${e.avizatorDetails?.role}`,
                    uuid: e.userUUID,
                    id: e.userRole,
                  })) || [];
              }
            }
          }
        );
      } else if (q.needApprover) {
        this.getNeedUsers();
      }
      let r = await NEEDS_ONCE(id);
      if (
        (r.status && String(r.status) !== "2") ||
        (r.statusCode &&
          String(r.statusCode) !== "2") ||
        (r.meta &&
          r.meta.HttpStatusCode &&
          String(r.meta.HttpStatusCode)[0] !== "2")
      ) {
        this.$toastr.e("Încercați mai târziu");
        this.closeWindow();
        return;
      }
      if (
        Number.isInteger(r?.data?.result?.id)
      ) {
        r = r.data.result;
        this.initReserve(
          NEED_RESERVATION.bind(null, false, r.id),
          NEED_RESERVATION.bind(null, true, r.id),
          this.closeWindow,
          'Nevoia'
        )

        const prepareFiles = [];
        if (Array.isArray(r.attachedDocuments)) {
          r.attachedDocuments.forEach((e) => {
            prepareFiles.push({
              file: {
                name: this.getNeedFileName(e),
              },
              keyFilename: e,
              deleted: false,
              __OLD_FILE__: true,
            });
          });
        }
        this.Modal1.files = this.Modal1.files.concat(prepareFiles).map((e) => {
          return Object.assign(e, { key: "I" + this.makeid(8) });
        });

        if (q.modal === 1.1) {
          this.Modal1.theCharacteristics =
            this.Modal1.describeTheCharacteristics.find(
              (e) =>
                e.name ===
                r.modalitateaUtilizataDescriereaCaracteristicilor
            );

          this.Modal1.achizitionCategory = r.achizitionCategory;
          this.Modal1.Input3 = r.estimatedPriceWoTva;
          this.Modal1.cotaTva = r.cotaTva;
          this.Modal1.Input5 = r.qty;
          this.Modal1.isSocial = r.needIsSocial;
          this.Modal1.Input10 = r.technicalDescription || "";
          this.Modal1.Input11 = avizoMode;
          this.Modal1.editorKey++;

          if (r.approvers && r.approvers.length > 0) {
            this.Modal1.approveState = true;
          }

          if (Array.isArray(r.caietulDeSarciniTrimiteri)) {
            const preparePaap = r.caietulDeSarciniTrimiteri.map(
              (e) =>
                ({
                  "SR EN ISO": 0,
                  "Evaluări tehnice europene [acolo unde este aplicabil]": 1,
                  "Specificaţii tehnice comune [acolo unde este aplicabil]": 2,
                  "Standarde internaţionale": 3,
                  "Sisteme de referinţă tehnice instituite de organisme de standardizare europene [acolo unde este aplicabil]": 4,
                  "Alte norme la nivel naţional care reglementează aspecte tehnice/specificaţii tehnice naţionale": 5,
                  "Conform descrierii tehnice menționate": 6,
                }[e])
            );

            preparePaap.forEach((e) => (this.Modal1.paap[e] = true));
          }
          
          let approver =
            Array.isArray(r.approvers) &&
            r.approvers[0]
              ? r.approvers[0]
              : false;
          approver = this.JSONvalide(approver)
            ? JSON.parse(approver)[0]?.split("*")
            : this.isString(approver, true)?.split("*") || [];

          
          const find = this.Modal1.Input9.find(
            (e) => e.uuid == approver[0] && e.id == approver[1]
          );
          if (!q.needApprover) {
            if (find) {
              this.Modal1.Input12 = find;
            } else {      
              this.Modal1.Input12 = null
            }
          }

          const w = r.product;
     
          window.requestAnimationFrame(() => {
            this.Modal1.selectedAvizo = avizors;
            this.Modal1.achizitionFromProduct = w.achizitionCategories || []
            this.Modal1.Input1 = {
              ...w,
              measurementUnit: w.measurementUnit?.shortName,
              productCpvCode: w.cpvCategory?.cpvCode,
              cpvName: w.name,
            };
          });
        }
        q.data = r;
        this.setLoad();
      }
    },
    getProduct(id, forDelete) {
      const insertData = data => {
        if (forDelete) {
          this.getWindow().productData = data;
          this.setLoad();
          this.Modal5Init(true);
          return;
        }
        this.Modal5.Input1 = data.name || "";
        this.Modal5.Input4 = data.description || "";
        this.Modal5.Input5 = data.institution || "";

        if (data.cpvCategory) {
          this.Modal5.Input6 =
            this.Modal5.types.find(
              (e) => e.id === data.cpvCategory.cpvType
            ) || "";
          this.Modal5.Input2 = data.cpvCategory || "";
          this.Modal5.achizitionCategories = data.achizitionCategories || [];
          const x = data.measurementUnit;
          this.Modal5.Input3 =
            { ...x, name: `${x.shortName} — „${x.description}”` } || "";
          if (data.cpvCategory) {
            this.Data.cpvCategories.push(data.cpvCategory);
          }
        }
        this.getWindow().productData = data;
        this.setLoad();

        this.Modal5Init();
      }
      const defaultData = this.getWindow()?.defaultData
      if(this.isObject(defaultData)) {
        insertData(defaultData)
        return
      }
      if(!Number.isInteger(id)) {
        return
      }
      this.setSafeLoad(12000);
      PRODUCTS_ONCE(id)
        .then((responseData) => {
          if (
            !this.isObject(responseData?.data?.result)
          ) {
            this.$toastr.e("Încercați mai târziu");
            this.closeWindow();
            return;
          }

          insertData(responseData.data.result)
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
          this.setLoad();
        });
    },
    getBudget(id, forDelete) {
      this.setSafeLoad(12000);
      BUDGET_ONCE(id)
        .then((responseData) => {
          if (
            !(
              responseData &&
              responseData.data &&
              responseData.data.result &&
              responseData.meta &&
              responseData.meta.HttpStatusCode === 200
            )
          ) {
            this.$toastr.e("Încercați mai târziu");
            this.closeWindow();
            return;
          }
          responseData = responseData.data.result;
          if (forDelete) {
            this.getWindow().budgetData = responseData;
            this.setLoad();
            return;
          }

          this.Modal7.Input1 = responseData.name || "";
          this.Modal7.Input2 = responseData.description || "";

          this.getWindow().budgetData = responseData;
          this.setLoad();
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
          this.setLoad();
        });
    },
    getProcedureType(id, forDelete) {
      this.setSafeLoad(12000);
      PROCEDURE_TYPE_ONCE(id)
        .then((responseData) => {
          if (
            !(
              responseData &&
              responseData.data &&
              responseData.data.result &&
              responseData.meta &&
              responseData.meta.HttpStatusCode === 200
            )
          ) {
            this.$toastr.e("Încercați mai târziu");
            this.closeWindow();
            return;
          }
          responseData = responseData.data.result;
          if (forDelete) {
            this.getWindow().procedureTypeData = responseData;
            this.setLoad();
            return;
          }

          this.Modal9.Input1 = responseData.name || "";
          this.Modal9.Input2 = responseData.description || "";

          this.getWindow().procedureTypeData = responseData;
          this.setLoad();
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
          this.setLoad();
        });
    },
    getCpvCategory(id, forDelete) {
      this.setSafeLoad(12000);
      CPV_CATEGORY_ONCE(id)
        .then((responseData) => {
          if (
            !(
              responseData?.data?.result &&
              responseData?.meta?.HttpStatusCode === 200
            )
          ) {
            this.$toastr.e("Încercați mai târziu");
            this.closeWindow();
            return;
          }
          responseData = responseData.data.result;
          if (forDelete) {
            this.getWindow().cpvCategoryData = responseData;
            this.setLoad();
            return;
          }

          this.Modal8.Input1 = responseData.name || "";
          this.Modal8.Input2 = responseData.cpvCode || "";

          this.getWindow().cpvCategoryData = responseData;
          this.setLoad();
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
          this.setLoad();
        });
    },
    getMeasurmentUnit(id, forDelete) {
      this.setSafeLoad(12000);
      MEASUREMENT_UNIT_ONCE(id)
        .then((responseData) => {
          if (
            !(
              responseData &&
              responseData.data &&
              responseData.data.result &&
              responseData.meta &&
              responseData.meta.HttpStatusCode === 200
            )
          ) {
            this.$toastr.e("Încercați mai târziu");
            this.closeWindow();
            return;
          }
          responseData = responseData.data.result;
          if (forDelete) {
            this.getWindow().muData = responseData;
            this.setLoad();
            return;
          }

          this.Modal9.Input1 = responseData.shortName || "";
          this.Modal9.Input2 = responseData.description || "";

          this.getWindow().muData = responseData;
          this.setLoad();
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.closeWindow();
          this.setLoad();
        });
    },
  },
  watch: {
    async Window(val) {
      if (stopWatcher) {
        stopWatcher = false;
        return;
      }
      if (val.length) {
        this.updateRoleStatus();
        this.syncData();
        this.Window.forEach((e) => {
          e.ID = "ID" + this.makeid(7);
        });

        this.initAllPermisions();
      }
      const m = val[val.length - 1];
      const r = window.requestAnimationFrame

      if (val && m) {
        if(blockFromBgClickMap.includes(+m.modal)) {
          this.$set(m, 'disabledBg', true)
        }

        if (m.modal === 1 || m.modal === 1.1) {
          this.Modal1Init();
          this.displayCurrentDateAndTime();
        } else {
          this.clearCurrentTimeIntervals();
        }
        if (m.modal === 5) {
          this.Modal5Init();
        }
        if ((m.modal === 5.1 || m.modal === 5.2)) {
          this.getProduct(
            +m.productId,
            m.modal === 5.1 ? false : true
          );
        }
        if (m.modal === 7) {
          this.Modal7Init();
        }
        if ((m.modal === 7.1 || m.modal === 7.2) && m.budgetId) {
          this.Modal7Init();
          r(() => {
            r(() => {
              r(() => {
                this.getBudget(
                  parseInt(m.budgetId),
                  m.modal === 7.1 ? false : true
                );
              });
            });
          });
        }
        if (m.modal === 8) {
          this.Modal8Init();
        }
        if ((m.modal === 8.1 || m.modal === 8.2) && m.cpvCategoryId) {
          this.Modal8Init();
          r(() => {
            r(() => {
              r(() => {
                this.getCpvCategory(
                  parseInt(m.cpvCategoryId),
                  m.modal === 8.1 ? false : true
                );
              });
            });
          });
        }
        if (m.modal === 11) {
          const userData = this.$jwt.decode(this.$jwt.getToken());
          const last_name = userData.last_name || "";
          const first_name = userData.first_name || "";
          const currentDate = window.$getDateAndTime();
          const x = this.getWindow();

          if (x.rejectData) {
            x.rejectData.rejectedByUser = String(
              `${last_name} ${first_name}`
            ).trim();
            x.rejectData.rejectedTime = String(currentDate);
          } else {
            x.user = String(`${last_name} ${first_name}`).trim();
            x.date = String(currentDate);
          }
        }
        if (m.modal === 12) {
          const proposals = await this.getProposals();
          if (proposals && Array.isArray(proposals) && proposals.length) {
            this.Data.proposalData = proposals || [];
          } else {
            this.emptyData.proposalData = true;
          }
        }
        if (m.modal === 13 && Number.isInteger(m.select)) {
          this.$nextTick(() => {
            this.changeNeedFile(m.select);
          });
        }
        if (m.modal === 20 || m.modal === 20.1) {
          this.Modal20Init();
        }
        if ([20.1, 20.3].includes(m.modal)) {
          const x = this.getWindow().data || {};
          USERS_ONCE(x.uuid)
            .then((res) => {
              if(!this.isObject(res?.data?.result)) {
                this.$toastr.e();
                this.Modal20.errorRoles = true;
                return
              }
              
              res = res.data.result

              if (Array.isArray(res?.userRoles)) {
                this.Modal20.roles = res.userRoles.map((e) => ({
                  role: e?.role ?? null,
                  institution: e.institution ?? null,
                  department: e.department ?? null,
                  status: e.userRoleStatus ?? null,
                  isConsilier: {
                    model: e.isConsilier ?? false
                  },
                  id: e.id,
                }));
                const modal = this.Window[this.Window.length - 1];
                if (modal?.data) {
                  modal.data.roles = [...this.Modal20.roles];
                }
              } else {
                this.$toastr.e();
                this.Modal20.errorRoles = true;
              }
            })
            .catch((err) => {
              this.$toastr.e(err);
              this.Modal20.errorRoles = true;
            });
        }
      }
    },
    userRole(val) {
      this.updateRoleStatus(val);
    },
  },
  created() {
    this.updateRoleStatus();
    document.addEventListener('keyup', ev => {
      if (ev?.key === "Escape") {
        if(window._PreventEscCloseForPopup) return;
        
        this.closeWindow(true)
      }
    })
  },
 
};
</script>

<style lang="scss">
.pagination_loader {
  display: block;
  padding: 1rem;
  text-align: center;
  color: #bbb;
}
.pagination_loader {
  padding-top: 100px;
  margin-top: -100px;
}
.modal5-names {
  position: absolute;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  background: white;
  border: 1px solid rgba(60,60,60,0.26);
  border-radius: 4px;
  z-index: 10000;
 
  &-item {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    padding: 0.6rem 0.875rem;
  }

  &-item:hover{
    background: #5897fb;
    color: white;
    cursor: pointer;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  margin-bottom: 2px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  top: 0;
  bottom: 0;
  margin: auto;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
